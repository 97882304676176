import { AccountInfo } from '@azure/msal-common';
import {
    Persona,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    MenuGroup,
    MenuGroupHeader,
    Avatar,
} from '@fluentui/react-components';
import {
    Settings24Regular,
    SignOut24Regular,
    DarkTheme24Regular,
} from '@fluentui/react-icons';
import { useEffect, useContext, useRef } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import useAppSelector from '../../Hooks/useAppSelector';
import useLogging from '../../Hooks/useLogging';
import useUser from '../../Hooks/useUser';
import useUserProfile from '../../Hooks/useUserProfile';
import EventTypes from '../../Models/EventTypes';
import { User } from '../../Models/User';
import {
    toggleThemeAction,
    updateSettingsDialogOpen,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import '../../Styles/Styles.css';

interface UserProfileProps {}

const UserProfile: React.FC<UserProfileProps> = () => {
    const { getProfilePictureUrl, signOut } = useUserProfile();
    const { updateUser, getUser } = useUser();
    const { logEvent } = useLogging();
    let isHostedInTeams = useAppSelector(
        (store) => store.globalState.isHostedInTeams,
    );
    useEffect(() => {
        async function getProfilePicture() {
            await getProfilePictureUrl();
        }
        async function getInitialUser() {
            await getUser();
        }
        getProfilePicture();
        getInitialUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userProfile = useAppSelector((store) => store.userProfile);
    const initialUser: User = useAppSelector((store) => store.user);

    //Handler for theme change
    const handleThemeChange = async () => {
        try {
            const newUser: User = {
                ...initialUser,
                theme: initialUser.theme === 'Dark' ? 'Light' : 'Dark',
            };
            //Call API to update user then update store
            await updateUser(newUser);
            updateStore(toggleThemeAction());
        } catch (error) {
            console.error('Error updating theme', error);
        }
    };

    if (!userProfile) return <></>;

    return (
        <>
            <Menu>
                <MenuTrigger>
                    <Persona
                        avatar={{
                            image: { src: userProfile.profilePictureUrl },
                        }}
                        name={userProfile.name}
                        primaryText={userProfile.name}
                        secondaryText={userProfile.email}
                        presence={{ status: 'available' }}
                        onClick={() => {
                            console.info('Persona clicked');
                            logEvent(EventTypes.USER_PROFILE_ICON_CLICKED);
                        }}
                        style={{
                            cursor: 'pointer',
                            width: '-webkit-fill-available',
                        }}
                    />
                </MenuTrigger>
                <MenuPopover style={{ width: '100%' }}>
                    <MenuList>
                        <MenuGroup>
                            <MenuGroupHeader>Profile Settings</MenuGroupHeader>
                            <MenuItem
                                icon={<Settings24Regular />}
                                onClick={() =>
                                    updateStore(updateSettingsDialogOpen())
                                }
                            >
                                Settings
                            </MenuItem>
                            {!isHostedInTeams && (
                                <MenuItem
                                    icon={<DarkTheme24Regular />}
                                    onClick={async () =>
                                        await handleThemeChange()
                                    }
                                >
                                    Theme
                                </MenuItem>
                            )}
                            {!isHostedInTeams && (
                                <MenuItem
                                    icon={<SignOut24Regular />}
                                    onClick={async () => {
                                        await signOut();
                                    }}
                                >
                                    Signout
                                </MenuItem>
                            )}
                        </MenuGroup>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    );
};

export default UserProfile;
