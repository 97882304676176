import {
    makeStyles,
    Body1,
    Button,
    shorthands,
    Card,
    CardHeader,
    CardPreview,
    Divider,
    Text,
    Popover,
    PopoverTrigger,
    PopoverSurface,
} from '@fluentui/react-components';
import {
    DocumentCopyRegular,
    CheckboxCheckedRegular,
    HistoryFilled,
} from '@fluentui/react-icons';
import * as React from 'react';
import { useState, useRef } from 'react';

import {
    ChatResponseContentApiResource,
    ChatResponseApiResource,
} from '../../Models/ChatThread';
import ChatCardResources from '../ChatCardResources';
import MarkdownViewer from '../MarkdownViewer';

const useStyles = makeStyles({
    historyIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: 'var(--colorNeutralForeground1)',
    },
    card: {
        ...shorthands.margin('auto'),

        display: 'flex',
        justifyContent: 'center',
        marginBottom: '15px',
        backgroundColor: 'var(--colorNeutralBackground1)',
    },
    CardPreview: {},
    header2: {
        width: '300px',
        marginLeft: '50px',
        backgroundColor: 'var(--colorNeutralBackground2)',
        paddingLeft: '7px',
        paddingRight: '7px',
        paddingTop: '7px',
        paddingBottom: '7px',
    },
    refHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    copyButton: {
        position: 'absolute', // Position absolute for the button
        top: '10px', // Distance from the top of the container
        right: '10px', // Distance from the right of the container
        zIndex: 1, // Ensure the button is above the code block
    },
});

type AuroraChatCardsProps = {
    message: ChatResponseApiResource;
    windowWidth: number;
    inHistory: boolean;
};

export const AuroraChatCardsComponent: React.FC<AuroraChatCardsProps> = (
    props: AuroraChatCardsProps,
) => {
    const { message, windowWidth, inHistory } = props;
    const { content, references, thumbUp, thumbDown, isHistory } = message;
    const styles = useStyles();
    const cardPreviewRef = useRef<HTMLDivElement>(null);

    const [isChatHistory, setHistory] = React.useState(inHistory);
    // State to control the visibility of the popover
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    React.useEffect(() => {
        setHistory(inHistory);
    }, [inHistory]);

    const renderContent = (content: ChatResponseContentApiResource[]) => {
        return content.map(
            (item: ChatResponseContentApiResource, index: number) => {
                return (
                    <MarkdownViewer
                        key={index}
                        content={item.value}
                        windowWidth={windowWidth}
                        inAuroraMessage={true}
                    />
                );
            },
        );
    };

    return (
        <Card
            className={styles.card}
            style={{
                width: '100%',
            }}
        >
            <CardHeader
                image={
                    <img
                        src="/logo192.png"
                        alt="Aurora"
                        style={{ width: 40, height: 40, borderRadius: '50%' }}
                    />
                }
                header={
                    <Body1 style={{ marginTop: '10px' }}>
                        <b>Aurora</b>
                    </Body1>
                }
                action={
                    isChatHistory ? (
                        <Popover open={isPopoverOpen}>
                            <PopoverTrigger>
                                <Button
                                    appearance="transparent"
                                    icon={<HistoryFilled />}
                                    style={{
                                        marginRight: '10px',
                                    }}
                                    onMouseEnter={() => setIsPopoverOpen(true)}
                                    onMouseLeave={() => setIsPopoverOpen(false)}
                                />
                            </PopoverTrigger>
                            <PopoverSurface
                                tabIndex={-1}
                                style={{
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    fontSize: '12px',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                }}
                            >
                                Included in history
                            </PopoverSurface>
                        </Popover>
                    ) : null
                }
            />

            <CardPreview
                className={styles.CardPreview}
                ref={cardPreviewRef}
                style={{
                    paddingRight: windowWidth < 900 ? '35px' : '60px',
                    paddingLeft: windowWidth < 900 ? '20px' : '60px',
                }}
            >
                {renderContent(content)}
            </CardPreview>

            <Divider></Divider>
            {/* Call resources component */}
            <ChatCardResources
                threadId={message.threadId}
                messageId={message.id}
                references={references ?? []}
                thumbUp={thumbUp}
                thumbDown={thumbDown}
                isHistory={isHistory}
            />
        </Card>
    );
};

export default AuroraChatCardsComponent;
