import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '../../../Models/User';
const localStorage: any = window.localStorage;
const initialState: User = {
    id: '',
    theme: localStorage['theme'] ?? 'Dark',
    chatHistoryLimit: 10,
};

export const UserSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        updateUserAction: (state, action: PayloadAction<User>) => {
            if (action.payload.theme !== state.theme) {
                localStorage['theme'] = action.payload.theme;
            }
            return { ...state, ...action.payload };
        },
        toggleThemeAction: (state) => {
            const newTheme = state.theme === 'Dark' ? 'Light' : 'Dark';
            localStorage['theme'] = newTheme;
            return { ...state, theme: newTheme };
        },
    },
});
