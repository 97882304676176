import { Toaster } from '@fluentui/react-components';
import React, { useEffect } from 'react';

import { ErrorToast } from 'Components/ErrorToast';
import { ERROR_MESSAGE } from 'Constants';
import { useErrorToast } from 'Hooks/useErrorToast';

import SkillCard from '../../Components/SkillCard';
import { ChatThreadModel } from '../../Models/ChatThread';
import { Skill } from '../../Models/Skill';
import { usePatchThreadSkillsMutation } from '../../Services/API/Aurora';
import {
    disableChat,
    enableChat,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import '../../Styles/SkillMenu.Module.css';

interface SkillCardContainerProps {
    threadDetails: ChatThreadModel;
}

export interface SelectedSkillsModel {
    skillid: number;
    isSelected: boolean;
}

const SkillCardContainer: React.FC<SkillCardContainerProps> = ({
    threadDetails,
}) => {
    const [patchThreadSkills, { isLoading: isUpdatingSkills }] =
        usePatchThreadSkillsMutation();

    useEffect(() => {
        if (isUpdatingSkills) {
            updateStore(disableChat());
            return;
        }
        updateStore(enableChat());
    }, [isUpdatingSkills]);
    //Handlers
    const threadId: number = threadDetails.id;
    const handleSkillSelection = (
        id: number,
        skillName: string,
        isChild: boolean,
    ) => {
        let needsParentSkillUpdate = false;
        let skillsHolder: Skill[] = threadDetails.skills!;
        let setThreadsArray: SelectedSkillsModel[] = [];
        const toggleChildSkills = (skill: Skill, isSelected: boolean) => {
            skill.childSkills.map((childSkill) => {
                if (childSkill.isSelected !== isSelected) {
                    setThreadsArray.push({
                        skillid: childSkill.id,
                        isSelected: isSelected,
                    });
                }
            });
        };
        const toggleSkill = (skill: Skill, isSelected: boolean) => {
            setThreadsArray.push({
                skillid: skill.id,
                isSelected: isSelected,
            });
        };

        const parentSkill = threadDetails.skills!.find((skill) =>
            skill.childSkills?.some((childSkill) => childSkill.id === id),
        );
        if (isChild) {
            const updatedSkills = threadDetails.skills!.map((skill) => {
                // find parent skill
                if (skill.id === parentSkill?.id) {
                    const updatedChildSkills = skill.childSkills.map(
                        (childSkill) => {
                            // find subject child skill
                            if (childSkill.id === id) {
                                toggleSkill(childSkill, !childSkill.isSelected);
                                return {
                                    ...childSkill,
                                    isSelected: !childSkill.isSelected,
                                };
                            }
                            return childSkill;
                        },
                    );

                    const isAnyChildSkillSelected = updatedChildSkills.some(
                        (childSkill) => childSkill.isSelected,
                    );
                    //If parent skill is selected and no child skill is selected, disable parent skill
                    if (skill.isSelected && !isAnyChildSkillSelected) {
                        needsParentSkillUpdate = true;
                    }
                    //If parent skill is not selected and child skill is selected, enable parent skill
                    if (!skill.isSelected && isAnyChildSkillSelected) {
                        needsParentSkillUpdate = true;
                    }
                    return {
                        ...skill,
                        childSkills: updatedChildSkills,
                    };
                }
                return skill;
            });
            //Set skillname to parent if function was called on child skill
            skillName = parentSkill?.name || skillName;
            skillsHolder = updatedSkills;
        }

        //Parent skill section
        if (needsParentSkillUpdate || !isChild) {
            const chosenSkill = isChild
                ? parentSkill
                : threadDetails.skills!.find((skill) => skill.id === id);

            //If skill is being enabled, set tag to disable all special skills
            const disableSpecial =
                chosenSkill?.isSelected === false ? true : false;
            //If skill is special and being enabled, set tag to disable all other skills
            const disableAll =
                (chosenSkill?.isSpecial === true ||
                    parentSkill?.isSpecial === true) &&
                disableSpecial === true
                    ? true
                    : false;
            const updatedSkills = skillsHolder.map((skill) => {
                //Block for toggling chosen skill
                if (skill.id === id) {
                    toggleSkill(skill, !skill.isSelected);
                    //If skill is being disabled, disable all child skills
                    if (skill.isSelected === true) {
                        toggleChildSkills(skill, false);
                        let tempChildSkills = skill.childSkills.map(
                            (childSkill) => ({
                                ...childSkill,
                                isSelected: false,
                            }),
                        );
                        return {
                            ...skill,
                            childSkills: tempChildSkills,
                            isSelected: !skill.isSelected,
                        };
                    }
                    //If skill is being enabled & didn't come from a child being enabled, enable all child skills
                    else if (skill.isSelected === false && !isChild) {
                        toggleChildSkills(skill, true);
                        let tempChildSkills = skill.childSkills.map(
                            (childSkill) => ({
                                ...childSkill,
                                isSelected: true,
                            }),
                        );
                        return {
                            ...skill,
                            childSkills: tempChildSkills,
                            isSelected: !skill.isSelected,
                        };
                        // Child skills is being disabled and there are no remaining child skills
                    }
                    return {
                        ...skill,
                        isSelected: !skill.isSelected,
                    };
                    //Block for disabling all skills if special skill is enabled
                } else if (disableAll === true && skill.isSelected === true) {
                    toggleSkill(skill, false);
                    toggleChildSkills(skill, false);
                    return {
                        ...skill,
                        isSelected: false,
                        childSkills: skill.childSkills.map((childSkill) => ({
                            ...childSkill,
                            isSelected: false,
                        })),
                    };
                    //If skill is not special and being enabled, just disable special skills
                } else if (
                    disableSpecial === true &&
                    skill.isSelected === true &&
                    skill.isSpecial === true
                ) {
                    toggleSkill(skill, false);
                    toggleChildSkills(skill, false);
                    return {
                        ...skill,
                        isSelected: false,
                        childSkills: skill.childSkills.map((childSkill) => ({
                            ...childSkill,
                            isSelected: false,
                        })),
                    };
                } else if (
                    skill.id === parentSkill?.id &&
                    needsParentSkillUpdate
                ) {
                    // if it's last remaining child skills, disable parent
                    if (needsParentSkillUpdate) {
                        toggleSkill(skill, !skill.isSelected);
                    }
                    return { ...skill, isSelected: !skill.isSelected };
                }
                return skill;
            });
            skillsHolder = updatedSkills;
        }

        patchThreadSkills({
            threadId,
            setSkills: setThreadsArray,
            setLocalSkills: skillsHolder,
        });
    };

    const sortedSkills = [...threadDetails.skills!].sort(
        (a, b) => a.orderBy - b.orderBy,
    );

    return (
        <div className="SkillCardContainer">
            {sortedSkills.map((item, index) => (
                <>
                    <SkillCard
                        key={index}
                        skillData={item}
                        showSwitch={true}
                        handleSkillSelection={handleSkillSelection}
                        cardWidth="100%"
                        isUpdating={isUpdatingSkills}
                    />
                </>
            ))}
        </div>
    );
};

export default SkillCardContainer;
