import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Divider,
} from '@fluentui/react-components';

import useAppSelector from '../../Hooks/useAppSelector';

type EnableMicrophoneInstructionsProps = {
    onPopupClosed: () => void;
};

export default function EnableMicrophoneInstructions({
    onPopupClosed,
}: EnableMicrophoneInstructionsProps) {
    const isHostedInTeams = useAppSelector(
        (store) => store.globalState,
    ).isHostedInTeams;

    const showTeamsInstructionsIfNeeded = () => {
        if (!isHostedInTeams) return;
        return (
            <div style={{ paddingTop: '10px' }}>
                <h4 style={{ marginTop: 0, marginBottom: 0 }}>
                    Click on the shield icon on top right corner of your Teams
                    app and toggle on "Media" permission as shown below.
                </h4>
                <div style={{ paddingTop: '10px' }}>
                    <img src="msteams-app-permission.png" />
                </div>
            </div>
        );
    };

    const showBrowserInstructions = () => {
        if (isHostedInTeams) return;
        return (
            <div style={{ paddingTop: '10px' }}>
                <h4 style={{ marginTop: 0, marginBottom: 0 }}>
                    Click on the padlock icon as shown below and allow access to
                    microphone.
                </h4>
                <div style={{ paddingTop: '10px' }}>
                    <img src="browser-allow-microphone.png" />
                </div>
            </div>
        );
    };

    return (
        <>
            <Dialog open={true} modalType="modal">
                <DialogSurface>
                    <DialogTitle
                        style={{
                            marginBottom: '10px',
                        }}
                    >
                        Microphone Permission Needed!
                    </DialogTitle>
                    <Divider />
                    <DialogBody>
                        <DialogContent>
                            <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                                You must first give this application permission
                                to use your microphone.
                            </h3>
                            {showTeamsInstructionsIfNeeded()}
                            {showBrowserInstructions()}
                        </DialogContent>
                    </DialogBody>
                    <Divider
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                    />
                    <DialogActions
                        style={{ display: 'flex', justifyContent: 'end' }}
                    >
                        <Button
                            appearance="primary"
                            onClick={() => onPopupClosed()}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </DialogSurface>
            </Dialog>
        </>
    );
}
