import { unionBy } from 'lodash';

import { ERROR_MESSAGE } from 'Constants';
import { setToastMessage } from 'Services/StateManagement/Actions';

import { AuroraEndpointBuilder } from '..';
import { GetThreadMessagesResponse, GetThreadMessagesRequest } from '../dto';

export const getThreadMessages = (builder: AuroraEndpointBuilder) =>
    builder.query<GetThreadMessagesResponse, GetThreadMessagesRequest>({
        query: ({ threadId, pageNumber }) =>
            `/v1/Chat/GetMessages?threadId=${threadId}&PageNumber=${pageNumber}`,
        transformResponse: (response: { data: GetThreadMessagesResponse }) => {
            // if last message has no response, mark it for retry
            const lastIndex = response.data.items.length - 1;
            const message = response.data.items[lastIndex];
            if (message.parentId === null) {
                message.didError = true;
            }
            return response.data;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
            `${endpointName}?threadId=${queryArgs.threadId}`,
        merge: (currentCache, newItems) => {
            return {
                ...newItems,
                items: unionBy(newItems.items, currentCache.items, 'id').sort(
                    (a, b) =>
                        new Date(a.createdAt).valueOf() -
                        new Date(b.createdAt).valueOf(),
                ),
            };
        },
        transformErrorResponse: (response: { status: string | number }) => {
            return response.status;
        },
        forceRefetch({ currentArg, previousArg }) {
            return currentArg?.pageNumber !== previousArg?.pageNumber;
        },
        providesTags: (_result, _error, { threadId }) => [
            {
                type: 'Messages' as const,
                id: threadId,
            },
        ],
        // result
        //     ? [
        //           ...result.items.map(({ id }) => ({
        //               type: 'Messages' as const,
        //               id,
        //           })),
        //           // 'Messages',
        //       ]
        //     : ['Messages'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ThreadMessagesGet,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
    });
