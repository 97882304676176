import { ToastPosition } from '@fluentui/react-components';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ErrorMessage } from '../../../Models/ErrorMessage';

const initialState: ErrorMessage = {
    message: undefined,
    debugInfo: undefined,
    requestId: undefined,
    position: 'bottom',
};

export const ErrorMessageSlice = createSlice({
    name: 'errorMessage',
    initialState: initialState,
    reducers: {
        setErrorMessage: (state, action: PayloadAction<ErrorMessage>) => {
            return { ...state, ...action.payload };
        },
        clearErrorMessage: () => {
            return {
                message: undefined,
                debugInfo: undefined,
                requestId: undefined,
                position: 'bottom' as ToastPosition,
            };
        },
    },
});
