import {
    makeStyles,
    shorthands,
    Text,
    tokens,
    Subtitle1,
    Card,
    CardHeader,
    Image,
    Caption1,
    CardPreview,
} from '@fluentui/react-components';
import * as React from 'react';

import '../../Styles/ChatCards.Module.css';
import { useGetThreadMessagesQuery } from 'Services/API/Aurora';

import MarkdownViewer from '../../Components/MarkdownViewer';
import useAppSelector from '../../Hooks/useAppSelector';
import { ChatResponseApiResource } from '../../Models/ChatThread';
import { getFileIcon } from '../../Utils/index';

const useStyles = makeStyles({
    scrollContainer: {
        display: 'flex', // Layout cards in a row
        overflowX: 'auto', // Enable horizontal scrolling
        scrollbarWidth: 'none', // Optionally hide the scrollbar (Firefox)
        '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
        },
        // Add padding and possibly other styles to ensure proper layout and spacing
    },
    cardLinks: {
        marginRight: '20px', // Add right margin to each card for spacing
        '&:last-child': {
            marginRight: '0', // Remove right margin for the last card
        },
        minWidth: '200px', // Set a minimum width for each card

        backgroundColor: tokens.colorNeutralBackground4,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground3,
            cursor: 'pointer',
        },
    },
    card: {
        marginRight: '20px', // Add right margin to each card for spacing
        '&:last-child': {
            marginRight: '0', // Remove right margin for the last card
        },
        minWidth: '200px', // Set a minimum width for each card

        backgroundColor: tokens.colorNeutralBackground3,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground3,
            cursor: 'pointer',
        },
    },
    title: {
        ...shorthands.margin(0, 0, '15px'),
        textAlign: 'center', // center align the title text
        marginBottom: '40px',
    },

    text: {
        ...shorthands.margin(0),
    },

    skillName: {
        ...shorthands.margin(0),
        justifyContent: 'flex-end',
    },
    horizontalCardImage: {
        width: '64px',
        height: '64px',
    },
});

const Title = ({ children }: React.PropsWithChildren<{}>) => {
    const styles = useStyles();

    return (
        <Subtitle1 as="h4" block className={styles.title}>
            {children}
        </Subtitle1>
    );
};
const ERMLinks: React.FC = () => {
    const styles = useStyles();

    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const { data } = useGetThreadMessagesQuery(
        {
            threadId: threadId ?? 0,
            pageNumber: 1,
        },
        { skip: !threadId },
    );

    const messages = data?.items;

    /*eslint-disable no-console*/
    if (!messages?.[1]) {
        return null;
    }
    const Aurora = messages[1]?.references;
    const content = messages[1]?.content;
    return (
        <div style={{ width: '90%', marginBottom: '30px' }}>
            <div className={styles.scrollContainer}>
                {Aurora &&
                    Aurora.map((auroraItem, index) => (
                        <>
                            <Card
                                className={styles.cardLinks}
                                style={{
                                    borderRadius: '10px',
                                    borderColor: tokens.colorNeutralBackground4,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
                                }}
                                onClick={() => {
                                    // TODO: add local file fetch here
                                    if (auroraItem.url) {
                                        window.open(
                                            auroraItem.url,
                                            '_blank',
                                            'noopener,noreferrer',
                                        );
                                    }
                                }}
                                key={index}
                            >
                                <CardHeader
                                    image={
                                        <img
                                            src={getFileIcon(
                                                auroraItem.name,
                                                auroraItem.url,
                                            )}
                                            alt="File icon"
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                            }}
                                        />
                                    }
                                    header={<b>{auroraItem.name}</b>}
                                />
                            </Card>
                        </>
                    ))}
            </div>
            <Card
                className={styles.card}
                style={{
                    borderRadius: 10,
                    marginTop: '20px',
                    // width: '20vw',

                    // Ensure there's no fixed height set here
                }}
            >
                {/* <CardHeader header={<Text weight="semibold">Summary</Text>} /> */}
                {/* <p
                    className={styles.text}
                    style={{
                        overflowWrap: 'break-word',
                    }}
                >
                    Chicken Recipe 01wsefwejfnhkwsdejfkejnfwkwnejfkwejfnwkejfnkwejdwedwdewde
                </p> */}
                <MarkdownViewer content={content[0].value}></MarkdownViewer>
                <Caption1
                    align="end"
                    style={{
                        position: 'absolute', // Position Caption1 absolutely
                        bottom: '5px', // Align Caption1 to the bottom
                        right: '10px', // Align Caption1 to the right
                        // This element does not contribute to the card's height due to absolute positioning
                    }}
                >
                    {/** Display the skill name */}
                </Caption1>
            </Card>
        </div>
    );
};

export default ERMLinks;
