import { ERROR_MESSAGE } from 'Constants';
import { MessageReactionType } from 'Models/ChatThread';
import { setToastMessage } from 'Services/StateManagement/Actions';

import { auroraApi, AuroraEndpointBuilder } from '..';

export const postMessageReaction = (builder: AuroraEndpointBuilder) =>
    builder.mutation<
        void,
        { threadId: number; messageId: number; reaction: MessageReactionType }
    >({
        query: (args) => ({
            url: `/v1/Chat/SetMessageReaction?threadId=${args.threadId}`,
            method: 'POST',
            body: { messageId: args.messageId, reaction: args.reaction },
        }),
        async onQueryStarted(
            args: {
                threadId: number;
                messageId: number;
                reaction: MessageReactionType;
            },
            { dispatch, queryFulfilled },
        ) {
            const updateReaction = dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadMessages',
                    {
                        threadId: args.threadId,
                        pageNumber: 0,
                    },
                    // TODO: fix this typing issue
                    (draft: any) => {
                        const updatedMessageIndex = draft.items.findIndex(
                            (item: { id: number }) =>
                                item.id === args.messageId,
                        );
                        if (updatedMessageIndex >= 0) {
                            if (args.reaction === 'ThumbUp') {
                                draft.items[updatedMessageIndex].thumbUp =
                                    !draft.items[updatedMessageIndex].thumbUp;
                                draft.items[updatedMessageIndex].thumbDown =
                                    false;
                            }
                            if (args.reaction === 'ThumbDown') {
                                draft.items[updatedMessageIndex].thumbDown =
                                    !draft.items[updatedMessageIndex].thumbDown;
                                draft.items[updatedMessageIndex].thumbUp =
                                    false;
                            }
                        }
                    },
                ),
            );
            try {
                await queryFulfilled;
            } catch (e) {
                updateReaction.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.Reaction,
                        body: ERROR_MESSAGE.TryAgain,
                        position: 'bottom',
                    }),
                );
            }
        },
    });
