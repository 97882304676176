import { useId, useToastController } from '@fluentui/react-components';
import { ReactNode, useEffect } from 'react';

type Params = {
    isError?: boolean;
    toast: ReactNode;
};

type Return = {
    toastId: string;
    trigger: () => void;
};

export const useErrorToast = (params: Params): Return => {
    const { isError, toast } = params;
    //Toaster
    const toastId = useId('toast');
    const { dispatchToast } = useToastController(toastId);
    const triggerToast = () =>
        dispatchToast(toast, { timeout: 5000, intent: 'error' });

    useEffect(() => {
        if (isError) {
            triggerToast();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    return { toastId, trigger: triggerToast };
};
