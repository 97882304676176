//useChatThread.ts

import * as WebRequestHelper from '../../Helpers/WebRequestHelper';
import { Skill, SkillModelArraySchema } from '../../Models/Skill';
import { updateUserSkills } from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import { validateDataAgainstSchema } from '../../Utils';
import useAuroraApi from '../useAuroraApi';

//Helper for validating schema
const checkForInvalidData = (data: Skill[] | null): void => {
    if (!data) {
        throw new Error('Data is null');
    }
    // Validate the data against the schema
    const isValidSchema = validateDataAgainstSchema<Skill[]>(
        data,
        SkillModelArraySchema,
    );
    // If the data does not match the schema, throw an error
    if (!isValidSchema) {
        throw new Error('Data does not match schema');
    }
};

const useUserSkills = () => {
    //Helper for API calls
    const { processApiRequest } = useAuroraApi();

    //Get User
    const getUserSkills = async (): Promise<Skill[] | undefined> => {
        // make API call to fetch the threads.
        try {
            const response = await processApiRequest({
                //todo: update the path to the correct endpoint.
                path: '/v1/UserSkills/GetUserSkills',
            });
            // handle the response here
            const data = response.data.data as Skill[];
            checkForInvalidData(data);
            // update userSkills in store.
            updateStore(updateUserSkills(data));
            return data;
        } catch (error) {
            console.error(
                'Error occurred while processing API request: getUserSkills',
                error,
            );
            throw error;
        }
    };

    //Update UserSkills
    const updateSkills = async (skills: Skill[]): Promise<void> => {
        try {
            // Make API call to update the user with the specified user
            const response = await processApiRequest({
                path: '/v1/UserSkills/UpdateUserSkills',
                method: WebRequestHelper.RequestMethods.PATCH,
                data: skills,
            });
        } catch (error) {
            console.error(
                'Error occurred while processing API request: updateSkills',
                error,
            );
            throw error;
        }
    };
    return { getUserSkills, updateSkills };
};

export default useUserSkills;
