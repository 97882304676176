import { hitFilesResponseSchema } from '../Models/RecentFileModel';

import * as WebRequestHelper from './WebRequestHelper';

export async function getProfilePicture(accessToken: string): Promise<Blob> {
    try {
        const url = 'https://graph.microsoft.com/v1.0/me/photo/$value';
        const response = await WebRequestHelper.processWebRequest({
            url,
            method: WebRequestHelper.RequestMethods.GET,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getRecentFiles(
    accessToken: string,
    searchTerm: string,
): Promise<any[]> {
    try {
        const pathResponse = await WebRequestHelper.processWebRequest({
            url: 'https://graph.microsoft.com/v1.0/me/drive',
            method: WebRequestHelper.RequestMethods.GET,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            responseType: 'json',
        });

        const path = pathResponse.data.webUrl;
        const title = `filename:*${searchTerm}*`;

        const response = await WebRequestHelper.processWebRequest({
            url: 'https://graph.microsoft.com/v1.0/search/query',
            method: WebRequestHelper.RequestMethods.POST,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                requests: [
                    {
                        entityTypes: ['driveItem'],
                        query: {
                            queryString: `${title} filetype:pptx OR filetype:docx OR filetype:xlsx OR filetype:pdf OR filetype:json OR filetype:jpeg OR filetype:jpg OR filetype:png OR filetype:html OR filetype:txt OR filetype:csv path:"${path}"`,
                        },
                        sortProperties: [
                            {
                                name: 'lastModifiedDateTime',
                                isDescending: true,
                            },
                        ],
                        fields: [
                            'id',
                            'name',
                            'webUrl',
                            'lastModifiedDateTime',
                            'parentReference',
                            'size',
                            'fileSystemInfo',
                        ],
                        from: 0,
                        size: 50,
                    },
                ],
            },

            responseType: 'json',
        });

        if (!response.data.value[0].hitsContainers[0].hits) {
            return [];
        }
        const hitFiles = hitFilesResponseSchema.parse(
            response.data.value[0].hitsContainers[0].hits,
        );
        const recentFiles = hitFiles.map((file) => file.resource);
        const uniqueFiles: { [key: string]: any } = recentFiles.reduce(
            (acc: { [key: string]: any }, current) => {
                if (!acc[current.id]) {
                    acc[current.id] = current; // Use file ID as the key
                }
                return acc;
            },
            {},
        );
        const uniqueFilesArray = Object.values(uniqueFiles);

        return uniqueFilesArray.map((file) => ({
            fileId: file.id,
            name: file.name,
            url: file.webUrl || 'URL not available',
            driveId: file.parentReference?.driveId || 'Drive ID not available',
            date:
                file.fileSystemInfo?.lastModifiedDateTime ||
                'Date not available',
            size: file.size,
            siteId: file.parentReference?.siteId || 'Site ID not available',
            listId:
                file.parentReference?.sharepointIds?.listId ||
                'List ID not available',
            listItemId:
                file.parentReference?.sharepointIds?.listItemId ||
                'List Item ID not available',
        }));
    } catch (error) {
        console.error('Error fetching recent files:', error);
        throw error;
    }
}
export async function getImageURL(
    accessToken: string,
    listItemId: string,
    siteId: string,
    listId: string,
    backupURL: string,
): Promise<string> {
    try {
        const response = await WebRequestHelper.processWebRequest({
            url: `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listId}/items/${listItemId}?select=id,webUrl`,
            method: WebRequestHelper.RequestMethods.GET,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            responseType: 'json',
        });

        if (!response.data.webUrl) {
            return backupURL;
        }

        return response.data.webUrl;
    } catch (error) {
        console.error('Error fetching recent files:', error);
        throw error;
    }
}
export async function getFileURL(
    accessToken: string,
    fileId: string,
    backupURL: string,
): Promise<string> {
    try {
        const response = await WebRequestHelper.processWebRequest({
            url: `https://graph.microsoft.com/v1.0/me/drive/root/search(q='${fileId}')?select=id,webUrl`,
            method: WebRequestHelper.RequestMethods.GET,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            responseType: 'json',
        });
        if (!response.data.value[0].webUrl) {
            return backupURL;
        }

        return response.data.value[0].webUrl;
    } catch (error) {
        console.error('Error fetching recent files:', error);
        throw error;
    }
}
