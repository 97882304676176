import { Switch, Text } from '@fluentui/react-components';
import React from 'react';

import { Skill } from '../../Models/Skill';

type ChildSkillProps = {
    skillData: Skill;
    handleChildSkillSelection: (
        id: number,
        skillName: string,
        isChild: boolean,
    ) => void;
};

const ChildSkill: React.FC<ChildSkillProps> = (props) => {
    return (
        <div className="ChildSkillContainer">
            <Text>{props.skillData.name}</Text>
            <Switch
                checked={props.skillData.isSelected}
                onChange={() => {
                    props.handleChildSkillSelection(
                        props.skillData.id,
                        props.skillData.name,
                        true,
                    );
                }}
            ></Switch>
        </div>
    );
};

export default ChildSkill;
