import { z } from 'zod';

export const fileSystemInfoSchema = z.object({
    createdDateTime: z.string().optional(),
    lastAccessedDateTime: z.string().optional(),
    lastModifiedDateTime: z.string().optional(),
});
export const parentReferenceSchema = z.object({
    driveId: z.string().optional(),
    driveType: z.string().optional(),
    id: z.string().optional(),
    siteId: z.string().optional(),
    sharepointIds: z
        .object({
            listId: z.string().optional(),
            listItemId: z.string().optional(),
        })
        .optional(),
});

export const recentFileSchema = z.object({
    id: z.string(),
    name: z.string(),
    webUrl: z.string().url().optional(),
    lastModifiedDateTime: z.string().optional(),
    parentReference: parentReferenceSchema.optional(),
    fileSystemInfo: fileSystemInfoSchema.optional(), // Include fileSystemInfo schema
    size: z.number().optional(),
});
export const recentFilesResponseSchema = z.array(recentFileSchema);
export const hitFileSchema = z.object({
    hitId: z.string(),
    rank: z.number(),
    summary: z.string(),
    resource: recentFileSchema,
});
export const hitFilesResponseSchema = z.array(hitFileSchema);
