import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
    Button,
    Link,
} from '@fluentui/react-components';
import * as React from 'react';

import {
    ERROR_REPORTING_EMAIL,
    ENCODED_USER_ERROR_SUBJECT,
    ENCODED_USER_ERROR_BODY,
} from '../../Constants';
import useAppSelector from '../../Hooks/useAppSelector';
import { ErrorMessage } from '../../Models/ErrorMessage';
import MarkdownViewer from '../MarkdownViewer';

type Props = {
    children: React.ReactElement;
};

export const ErrorDialog: React.FC<Props> = ({ children }: Props) => {
    const errorMessage: ErrorMessage = useAppSelector(
        (store) => store.errorMessage,
    );

    return (
        <>
            <Dialog modalType="modal">
                <DialogTrigger disableButtonEnhancement>
                    {children}
                </DialogTrigger>
                <DialogSurface>
                    <DialogBody style={{ maxHeight: '75vh' }}>
                        <DialogTitle
                            action={null}
                            style={{
                                textAlign: 'center',
                                fontSize: '12px',
                                marginBottom: '0px',
                                paddingBottom: '0px',
                            }}
                        >
                            <MarkdownViewer
                                content={errorMessage.message ?? 'Error'}
                            />
                        </DialogTitle>
                        <DialogContent className="custom-scrollbar">
                            <MarkdownViewer
                                content={errorMessage.debugInfo ?? ''}
                            />
                            If you wish to report this error,{' '}
                            <Link
                                href={`mailto:${ERROR_REPORTING_EMAIL}?subject=${ENCODED_USER_ERROR_SUBJECT}&body=${ENCODED_USER_ERROR_BODY(errorMessage)}`}
                            >
                                click here
                            </Link>{' '}
                            to email the AI Innovation Center{' '}
                            <DialogActions
                                style={{
                                    width: '100%',
                                    justifyContent: 'end',
                                    paddingTop: '16px',
                                }}
                            >
                                <DialogTrigger disableButtonEnhancement>
                                    <Button
                                        appearance="primary"
                                        style={{
                                            width: '30%',
                                            marginTop: '8px',
                                        }}
                                    >
                                        Close
                                    </Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};
export default ErrorDialog;
