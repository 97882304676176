import {
    Toast,
    ToastTitle,
    ToastBody,
    tokens,
    makeStyles,
} from '@fluentui/react-components';

const useStyles = makeStyles({
    root: {
        color: tokens.colorStatusDangerForeground1,
        backgroundColor: tokens.colorStatusDangerBackground1,
    },
});

type Props = {
    title: string;
    body?: string;
};

export const ErrorToast = ({ title, body }: Props) => {
    const classes = useStyles();

    return (
        <Toast className={classes.root}>
            <ToastTitle>{title}</ToastTitle>
            {body && <ToastBody>{body}</ToastBody>}
        </Toast>
    );
};
