import {
    Card,
    CardHeader,
    Spinner,
    Body1,
    Button,
    Link,
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';

import { AttachedFile } from 'Models/ChatThread';
import {
    useLazyDownloadThreadAttachmentQuery,
    useRemoveThreadAttachmentMutation,
} from 'Services/API/Aurora';
import { getFileIcon } from 'Utils';

type Props = {
    file: AttachedFile;
    index: number;
};

export const FileCard = ({ file, index }: Props): JSX.Element => {
    const { threadId } = file;

    const [removeThreadAttachment] = useRemoveThreadAttachmentMutation();
    const [fetchAttachment, { data: url }] =
        useLazyDownloadThreadAttachmentQuery();
    return (
        <Card
            key={index}
            style={{
                marginBottom: '5px',
                backgroundColor: 'var(--colorNeutralBackground5Hover)',
                border: '1px solid var(--colorNeutralBackground3Hover)',
                height: '42px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <CardHeader
                image={
                    file.isLoading ? (
                        <Spinner size="tiny" />
                    ) : (
                        <img
                            src={getFileIcon(file.name)}
                            alt="File icon"
                            style={{ width: '20px' }}
                        />
                    )
                }
                header={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Body1>
                            <Link
                                onClick={async () => {
                                    if (
                                        file.url &&
                                        file.url.startsWith('http')
                                    ) {
                                        window.open(file.url, '_blank');
                                        return;
                                    }
                                    const result = await fetchAttachment({
                                        threadId: threadId ?? 0,
                                        attachmentId: file.id ?? 0,
                                        fileName: file.name,
                                    });
                                    if (result.data) {
                                        const hiddenLink =
                                            document.createElement('a');
                                        hiddenLink.href = result.data;
                                        hiddenLink.target = '_blank';
                                        hiddenLink.download = file.name;
                                        hiddenLink.click();
                                    }
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: 'var(--colorStrokeFocus2)',
                                }}
                            >
                                {file.name}
                            </Link>
                        </Body1>
                        <div>
                            <Button
                                aria-label="dismiss"
                                appearance="transparent"
                                icon={
                                    <DismissRegular
                                        onClick={() => {
                                            removeThreadAttachment({
                                                threadId: threadId ?? 0,
                                                attachmentId: file.id ?? 0,
                                            });
                                        }}
                                    />
                                }
                            />
                        </div>
                    </div>
                }
            />
        </Card>
    );
};
