import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Banner } from '../../../Models/Banner';
import { updateBanner } from '../Actions';

const initialState: Banner = {
    id: 0,
    message: '',
    isHidden: true,
};

export const BannerSlice = createSlice({
    name: 'banner',
    initialState: initialState,
    reducers: {
        updateBanner: (state, action: PayloadAction<Banner | undefined>) => {
            return { ...state, ...action.payload };
        },
        updateBannerIsHidden: (state, action: PayloadAction<boolean>) => {
            return { ...state, isHidden: action.payload };
        },
    },
});
