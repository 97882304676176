import { AccountInfo } from '@azure/msal-common';
import { shorthands, makeStyles, tokens } from '@fluentui/react-components';
import React, { useState } from 'react';
import styled from 'styled-components';

import ERMContainerV2 from '../Containers/ERMContainerV2';
import LeftSidebarContainer from '../Containers/LeftSidebar';
import RightSidebarContainer from '../Containers/RightSidebar';
import useAppSelector from '../Hooks/useAppSelector';
import { updateERMLoading } from '../Services/StateManagement/Actions';
import '../Styles/ERMBorder.Module.css';

type GridContainerProps = {
    rightOpen: boolean;
};
type ERMProps = {};

const GridContainer = styled.div<GridContainerProps>`
    height: 100vh;
    background: var(--colorNeutralBackground2);
    display: flex;
`;

const Header = styled.div`
    height: 75px; /* Adjust height as needed */
    color: #fff; /* Adjust color as needed */
    display: flex;
    align-items: center;
    padding: 0 20px;
    /* Add more styling for the header here */
`;

const MainContent = styled.div`
    display: flex;
    padding: 0px;
    alignitems: flex-start;
    /* Add more styling for the main content here */
`;

const ToggleButton = styled.button`
    /* Add button styling here */
    background: #444;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
`;
const useStyles = makeStyles({
    root: {
        display: 'flex',
        backgroundColor: tokens?.colorNeutralBackground2,
        height: '100vh',

        ...shorthands.overflow('hidden'),
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding('16px'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
});
const ERM: React.FC<ERMProps> = () => {
    const ERMLoading = useAppSelector(
        (store) => store.userInterface.ERMLoading,
    );

    const styles = useStyles();
    return (
        // <div className={ERMLoading ? 'pulsingBorder' : ''}>
        <div
            className={styles.root}
            // style={{ height: ERMLoading ? '99vh' : '' }}
        >
            <ERMContainerV2 />
        </div>
        // </div>
    );
};

export default ERM;
