import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Terms } from '../../../Models/Terms';

const initialState: Terms = {
    id: 0,
    message: '',
    title: '',
};

export const TermsSlice = createSlice({
    name: 'terms',
    initialState: initialState,
    reducers: {
        updateTerms: (state, action: PayloadAction<Terms>) => {
            return { ...state, ...action.payload };
        },
    },
});
