// ChatThreadInteractions.tsx
import {
    Button,
    Input,
    Card,
    Text,
    ProgressBar,
    Toaster,
} from '@fluentui/react-components';
import {
    EditRegular,
    DeleteRegular,
    CheckmarkRegular,
    DismissRegular,
    CheckmarkFilled,
    EditFilled,
    DeleteFilled,
    DismissFilled,
    StarRegular,
    StarFilled,
} from '@fluentui/react-icons';
import React, { useEffect, useRef, useState } from 'react';

import '../../Styles/ChatThread.Module.css';
import { ErrorToast } from 'Components/ErrorToast';
import { ERROR_MESSAGE } from 'Constants';
import { useErrorToast } from 'Hooks/useErrorToast';

import useAppSelector from '../../Hooks/useAppSelector';
import { ChatThreadModel } from '../../Models/ChatThread';
import { usePatchThreadMutation } from '../../Services/API/Aurora';

type ChatThreadInteractionsProps = {
    item: ChatThreadModel;
    onSelect: () => void;
    handleDelete: ({ id, title }: { id: number; title: string }) => void;
    isDeleteInProgress: boolean;
    isLast: boolean;
};

const ChatThreadInteractions: React.FC<ChatThreadInteractionsProps> = ({
    item,
    onSelect,
    handleDelete,
    isDeleteInProgress,
    isLast,
}) => {
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const [
        patchThread,
        { isError: isPatchThreadError, isLoading: isPatchThreadLoading },
    ] = usePatchThreadMutation();
    const [isHovering, setIsHovering] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [itemName, setItemName] = useState(item.title);
    const [itemFavorite, setItemFavorite] = useState(item.isFavorite);
    const handleMouseEnter = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsHovering(true);
    };

    const handleMouseLeave = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsHovering(false);
    };
    const [isEditHovered, setIsEditHovered] = React.useState(false);
    const [isDeleteHovered, setIsDeleteHovered] = React.useState(false);
    const [isCheckmarkHovered, setIsCheckmarkHovered] = React.useState(false);
    const [isDismissHovered, setIsDismissHovered] = React.useState(false);
    const [isMoreHovered, setIsMoreHovered] = React.useState(false);

    const startEdit = () => {
        setIsEditing(true);
    };

    const stopEdit = () => {
        setIsEditing(false);
    };

    const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 50) {
            setItemName(inputValue);
        }
    };

    const handleEditConfirm = () => {
        patchThread({ id: item.id, title: itemName, isFavorite: itemFavorite });
        stopEdit();
    };

    const handleCancel = () => {
        setItemName(item.title);
        stopEdit();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleEditConfirm();
        }
        if (e.key === 'Escape') {
            stopEdit();
        }
    };
    const prevItemFavorite = useRef(itemFavorite);

    useEffect(() => {
        if (prevItemFavorite.current == !itemFavorite) {
            // ItemFavorite changed from true to false
            handleEditConfirm();
        }

        prevItemFavorite.current = itemFavorite;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemFavorite]);

    useEffect(() => {
        setItemName(item.title);
    }, [item]);

    return (
        <>
            <div
                className={`ChatThreadInteractions `}
                style={{ position: 'relative' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Card
                    appearance="subtle"
                    onClick={onSelect}
                    style={{
                        height: '36px', //edits hover height
                        width: '100%', //edits hover width
                        backgroundColor:
                            item.id === threadId
                                ? 'var(--colorNeutralBackground1)'
                                : isHovering
                                  ? 'var(--colorNeutralBackground2)'
                                  : 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius:
                            item.id === threadId
                                ? '10px'
                                : isHovering
                                  ? '10px'
                                  : '0',
                        paddingLeft: '10px',
                        flexDirection: 'row', // makes the input left-oriented
                        borderLeft:
                            item.id === threadId
                                ? '2px solid var(--colorBrandForeground1)'
                                : 'none',
                    }}
                >
                    {isEditing ? (
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <div>
                                <Input
                                    value={itemName}
                                    onChange={handleEditChange}
                                    onKeyDown={handleKeyDown}
                                    autoFocus
                                    style={{
                                        width: '75%', // makes input left-aligned
                                        textAlign: 'left',
                                        border: '1px solid #ccc',
                                    }}
                                />
                            </div>
                            <div className="editButtonGroup">
                                <Button
                                    icon={
                                        isCheckmarkHovered ? (
                                            <CheckmarkFilled />
                                        ) : (
                                            <CheckmarkRegular />
                                        )
                                    }
                                    onMouseEnter={() =>
                                        setIsCheckmarkHovered(true)
                                    }
                                    onMouseLeave={() =>
                                        setIsCheckmarkHovered(false)
                                    }
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleEditConfirm();
                                    }}
                                    style={{
                                        padding: '5px',
                                        border: 'none',
                                        color: 'var(--colorPaletteGreenForeground1)',
                                        cursor: 'pointer',
                                        background: 'transparent',
                                    }}
                                />
                                <Button
                                    icon={
                                        isDismissHovered ? (
                                            <DismissFilled />
                                        ) : (
                                            <DismissRegular />
                                        )
                                    }
                                    onMouseEnter={() =>
                                        setIsDismissHovered(true)
                                    }
                                    onMouseLeave={() =>
                                        setIsDismissHovered(false)
                                    }
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleCancel();
                                    }}
                                    style={{
                                        padding: '5px',
                                        border: 'none',
                                        color: 'var(--colorPaletteRedForeground1)',

                                        background: 'transparent',
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            title={itemName}
                        >
                            <Text
                                style={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {itemName}
                            </Text>
                        </div>
                    )}
                    {isHovering && !isEditing && (
                        <div className="hoverActions">
                            <Button
                                icon={
                                    isEditHovered ? (
                                        <EditFilled />
                                    ) : (
                                        <EditRegular />
                                    )
                                }
                                onMouseEnter={() => setIsEditHovered(true)}
                                onMouseLeave={() => setIsEditHovered(false)}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    startEdit();
                                }}
                                style={{
                                    padding: '5px',
                                    border: 'none',
                                    background: 'transparent',
                                }}
                            />
                            <Button
                                icon={
                                    isDeleteHovered ? (
                                        <DeleteFilled />
                                    ) : (
                                        <DeleteRegular />
                                    )
                                }
                                onMouseEnter={() => setIsDeleteHovered(true)}
                                onMouseLeave={() => setIsDeleteHovered(false)}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDelete({
                                        id: item.id,
                                        title: item.title,
                                    });
                                }}
                                style={{
                                    padding: '5px',
                                    border: 'none',
                                    background: 'transparent',
                                }}
                            />
                            <Button
                                icon={
                                    isMoreHovered || itemFavorite ? (
                                        <StarFilled />
                                    ) : (
                                        <StarRegular />
                                    )
                                }
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setItemFavorite(!itemFavorite);
                                }}
                                onMouseEnter={() => setIsMoreHovered(true)}
                                onMouseLeave={() => setIsMoreHovered(false)}
                                style={{
                                    padding: '5px',
                                    border: 'none',
                                    background: 'transparent',
                                }}
                            />
                        </div>
                    )}
                </Card>
                <ProgressBar
                    value={
                        isDeleteInProgress || isPatchThreadLoading
                            ? undefined
                            : 1
                    }
                    thickness="large"
                    style={{
                        borderRadius: '4px',
                        marginTop: '1px',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        display:
                            isDeleteInProgress || isPatchThreadLoading
                                ? 'block'
                                : 'none',
                    }}
                />
            </div>
        </>
    );
};

export default ChatThreadInteractions;
