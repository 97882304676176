import {
    makeStyles,
    shorthands,
    Text,
    tokens,
    Subtitle1,
    Card,
    CardHeader,
    Image,
    Caption1,
    Button,
    Textarea,
} from '@fluentui/react-components';
import { SendRegular, EditRegular } from '@fluentui/react-icons';
import * as React from 'react';
import '../../Styles/ChatCards.Module.css';
import { useEffect, useRef } from 'react';

import { useGetThreadMessagesQuery } from 'Services/API/Aurora';

import useAppSelector from '../../Hooks/useAppSelector';
import useStarterPrompts from '../../Hooks/usePrompt';
import { ChatResponseApiResource } from '../../Models/ChatThread';
import { StarterPrompt } from '../../Models/StarterPrompt';
import ERMLinks from '../ERMLinks';

import '../../Styles/ERMMicrophone.Module.css';

type ChatCardProps = {
    isSendMessageButtonDisabled: boolean;
    onSendMessageButtonClick: (message: string) => Promise<void>;
    resetTranscript: () => void;
    transcript: string;
    isRecording: boolean;
    toggleRecording: (isRecording?: boolean) => void;
    onChange: (message: string) => void;
};

const useStyles = makeStyles({
    main: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // This creates three columns
        width: '100%', // Take full width
        '@media(max-width: 1000px)': {
            gridTemplateColumns: 'repeat(2, 1fr)', // Two columns for medium/small screens
        },
    },
    card: {
        width: '95%',

        height: '130px',
        marginBottom: '12px',
        marginRight: '6px',
        marginLeft: '6px',
        paddingBottom: '8px',

        backgroundColor: tokens.colorNeutralBackground4,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground3,
            cursor: 'pointer',
        },
    },

    title: {
        ...shorthands.margin(0, 0, '10px'),
        textAlign: 'center', // center align the title text
    },

    text: {
        ...shorthands.margin(0),
    },

    skillName: {
        ...shorthands.margin(0),
        justifyContent: 'flex-end',
    },
    darkTintOverlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: '1',
    },
});

const Title = ({ children }: React.PropsWithChildren<{}>) => {
    const styles = useStyles();

    return (
        <Subtitle1 as="h4" block className={styles.title}>
            {children}
        </Subtitle1>
    );
};
const ERMReferenceCards: React.FC<ChatCardProps> = (props: ChatCardProps) => {
    const styles = useStyles();
    const ERMLoading = useAppSelector(
        (store) => store.userInterface.ERMLoading,
    );
    const {
        isSendMessageButtonDisabled,
        onSendMessageButtonClick,
        toggleRecording,
        resetTranscript,
        transcript,
        isRecording,
        onChange,
    } = props;

    //Starter Prompts
    const { getStarterPrompts } = useStarterPrompts();
    const [prompts, setPrompts] = React.useState<StarterPrompt[]>([]);

    const [message, setMessage] = React.useState('');
    const [sendButton, setSendButton] = React.useState(false);
    const [editText, setEditText] = React.useState(false);
    const [textareaValue, setTextareaValue] = React.useState('');
    const MAX_RECORDING_TIME_MILLISECONDS = 10000;

    const textAreaRef = useRef<HTMLDivElement>(null);
    const textareaInputRef = useRef<HTMLTextAreaElement>(null);

    const handleTextareaChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        /*eslint-disable no-console*/
        setTextareaValue(event.target.value);
        if (event.target.value !== '') {
            setSendButton(true);
        } else {
            setSendButton(false);
        }
    };

    useEffect(() => {
        if (editText && textareaInputRef.current) {
            const textarea = textareaInputRef.current;
            textarea.focus();
            textarea.setSelectionRange(
                textarea.value.length,
                textarea.value.length,
            );
        }
    }, [editText]);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                textAreaRef.current &&
                !textAreaRef.current.contains(event.target as Node)
            ) {
                setEditText(false);
                if (textareaValue !== '') {
                    setMessage(textareaValue);
                    setTextareaValue('');
                    setSendButton(true);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [textareaValue]);
    useEffect(() => {
        if (isRecording) {
            const timeoutId = setTimeout(() => {
                clickAuroraLogo();
            }, MAX_RECORDING_TIME_MILLISECONDS);

            return () => clearTimeout(timeoutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcript, isRecording]);

    const clickAuroraLogo = async () => {
        toggleRecording();
        if (transcript.trim() != '' && isRecording) {
            setMessage(transcript);
            setSendButton(true);
        }
    };
    const clickSendButton = async () => {
        if (textareaValue !== '') {
            setMessage(textareaValue);
            setTextareaValue('');
            setSendButton(true);
        }
        setSendButton(false);
        await onSendMessageButtonClick(message);
        setMessage('');
    };

    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const { data } = useGetThreadMessagesQuery(
        {
            threadId: threadId ?? 0,
            pageNumber: 1,
        },
        { skip: !threadId },
    );

    const messages = data?.items;

    let promptCard = 'How can I help you today?';
    if (isRecording) {
        transcript === '' ? (promptCard = '. . .') : (promptCard = transcript);
    } else if (message !== '') {
        promptCard = message;
    } else if (messages && messages[0]) {
        promptCard = messages[0].content[0].value;
    }

    return (
        <div
            style={{
                width: '100%', // Full width
                height: '100%', // Full viewport height
                paddingTop: '20px', // Top padding
                paddingBottom: '20px', // Bottom padding
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center', // Keep centering horizontally
                }}
            >
                {/* Static content like a header or a fixed image can go here */}
                {/*COME BACK TO THIS: Make this its own component*/}
                <Button
                    appearance="subtle"
                    style={{
                        background: 'transparent',
                        padding: '20px',
                        paddingTop: '50px', // Adjust padding as needed
                        overflow: 'visible',

                        WebkitTapHighlightColor: 'transparent', // Prevent blue highlight on tap for mobile                        outline: 'none', // Remove default outline on focus (consider accessibility implications)
                    }} // Increased padding and ensure overflow is visible
                    disabled={ERMLoading}
                    onClick={() => {
                        clickAuroraLogo();
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                        }}
                    >
                        <Image
                            alt="Aurora Logo"
                            src="/logo192.png"
                            shape="circular"
                            height={100}
                            width={100}
                            style={{
                                position: 'relative',
                                marginBottom: '50px',
                                marginTop: '20px',
                                zIndex: 2,
                            }}
                        />
                        {isRecording ? (
                            <div
                                className="mic-shadow"
                                style={{
                                    position: 'absolute',
                                    // Adjust these values as needed to ensure the mic-shadow is fully visible
                                    top: '20px',
                                    left: '0',
                                    width: '100px', // Example adjustment
                                    height: '100px', // Example adjustment
                                    // Additional styling for mic-shadow as needed
                                    zIndex: 1,
                                }}
                            ></div>
                        ) : null}
                        {ERMLoading ? (
                            <div
                                className="mic-loading"
                                style={{
                                    position: 'absolute',
                                    // Adjust these values as needed to ensure the mic-shadow is fully visible
                                    top: '20px',
                                    left: '0',
                                    width: '100px', // Example adjustment
                                    height: '100px', // Example adjustment
                                    // Additional styling for mic-shadow as needed
                                    zIndex: 1,
                                }}
                            ></div>
                        ) : null}
                    </div>
                </Button>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {' '}
                    {/* Parent div with Flexbox */}
                    <div
                        style={{
                            backgroundColor: tokens.colorNeutralBackground6,
                            paddingTop: '7px',
                            paddingBottom: '0px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            borderRadius: '10px',
                            marginRight: '20px',
                            marginLeft: '20px',
                            width: editText ? '50vw' : 'auto',
                        }}
                        ref={textAreaRef}
                        onClick={() => setEditText(true)}
                    >
                        {!editText ? (
                            <>
                                <Title>
                                    {promptCard}
                                    {promptCard !== '. . .' ? (
                                        <EditRegular
                                            style={{
                                                marginLeft: '10px',
                                                height: '18px',
                                            }}
                                        />
                                    ) : null}
                                </Title>
                            </>
                        ) : (
                            <Textarea
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    marginBottom: '10px',
                                    width: '100%',
                                }}
                                ref={textareaInputRef}
                                defaultValue={message}
                                onChange={handleTextareaChange}
                            ></Textarea>
                        )}
                    </div>
                    {sendButton && !isRecording ? (
                        <Button
                            appearance="subtle"
                            aria-label="Bold"
                            icon={<SendRegular />}
                            name="send"
                            value="send"
                            style={{
                                backgroundColor: 'transparent',
                            }}
                            onClick={() => {
                                clickSendButton();
                            }}
                        />
                    ) : null}
                </div>
                <p
                    style={{
                        marginBottom: '40px',
                        marginRight: '20px',
                        marginLeft: '20px',
                    }}
                >
                    Click on the Aurora Logo to ask a question
                </p>
                {/* <ERMLinks></ERMLinks> */}
            </div>
        </div>
    );
};

export default ERMReferenceCards;
