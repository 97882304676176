import { combineReducers } from 'redux';

import { auroraApi } from '../../API/Aurora';
import { BannerSlice } from '../Slices/BannerSlice';
import { ChatThreadsSlice } from '../Slices/ChatThreadsSlice';
import { ErrorMessageSlice } from '../Slices/ErrorMessageSlice';
import { GlobalStateSlice } from '../Slices/GlobalStateSlice';
import { TermsSlice } from '../Slices/TermsSlice';
import { ThreadSlice } from '../Slices/ThreadSlice';
import { ToastMessageSlice } from '../Slices/ToastMessageSlice';
import { UserInterfaceSlice } from '../Slices/UserInterfaceSlice';
import { UserProfileSlice } from '../Slices/UserProfileSlice';
import { UserSkillSlice } from '../Slices/UserSkillSlice';
import { UserSlice } from '../Slices/UserSlice';

const reducer = combineReducers({
    chatThreads: ChatThreadsSlice.reducer,
    errorMessage: ErrorMessageSlice.reducer,
    toastMessage: ToastMessageSlice.reducer,
    globalState: GlobalStateSlice.reducer,
    terms: TermsSlice.reducer,
    banner: BannerSlice.reducer,
    userSkills: UserSkillSlice.reducer,
    userProfile: UserProfileSlice.reducer,
    userInterface: UserInterfaceSlice.reducer,
    user: UserSlice.reducer,
    [auroraApi.reducerPath]: auroraApi.reducer,
    thread: ThreadSlice.reducer,
});

export default reducer;
