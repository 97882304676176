import {
    Slider,
    makeStyles,
    Divider,
    Dropdown,
    Option,
    OptionGroup,
    Button,
    Popover,
    PopoverTrigger,
    PopoverSurface,
} from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';
import * as React from 'react';

import { Skill } from '../../Models/Skill';
import { User } from '../../Models/User';
import SkillCard from '../SkillCard';

interface SettingsMenuAppearanceProps {
    userPreferences: User;
    setUserPreferences: React.Dispatch<React.SetStateAction<User>>;
    skills: Skill[];
    initialSkills: Skill[];
    setSkillsToSend: React.Dispatch<React.SetStateAction<Skill[]>>;
    setSkills: React.Dispatch<React.SetStateAction<Skill[]>>;
    setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles({
    dropdown: {
        maxWidth: '100%',
        minWidth: '100%',
        marginRight: '25px',
    },
    saveButton: {
        maxWidth: '80px',
        marginLeft: 'auto',
        marginRight: '15px',
    },
});

const SettingsMenuAppearance: React.FC<SettingsMenuAppearanceProps> = ({
    userPreferences,
    setUserPreferences,
    skills,
    initialSkills,
    setSkillsToSend,
    setSkills,
    setIsSaveDisabled,
}) => {
    const [cardHeight, setCardHeight] = React.useState<string | null>(null);
    const skillCardsFlexRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const selectedSkillsCount = skills.filter(
            (skill) => skill.isSelected,
        ).length;
        const height =
            selectedSkillsCount > 1
                ? `${skillCardsFlexRef.current?.clientHeight}px`
                : '';
        setCardHeight(height);
    }, [skills]);

    //State
    const [updatedChatHistoryLimit, setUpdatedChatHistoryLimit] =
        React.useState<number>(userPreferences.chatHistoryLimit);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    //Handlers
    const handleSkillSelection = (id: number) => {
        const chosenSkill = skills.find((skill) => skill.id === id);
        const disableAll =
            chosenSkill?.isSpecial === true && chosenSkill.isSelected === false
                ? true
                : false;
        const disableSpecial = chosenSkill?.isSelected === false ? true : false;
        const updatedSkills = skills.map((skill) => {
            if (skill.id === id) {
                //If skill has child skills, toggle skill & all child skills
                if (skill.childSkills.length > 0) {
                    return {
                        ...skill,
                        isSelected: !skill.isSelected,
                        childSkills: skill.childSkills.map((childSkill) => ({
                            ...childSkill,
                            isSelected: !skill.isSelected,
                        })),
                    };
                }
                //If skill has no child skills, toggle skill
                return {
                    ...skill,
                    isSelected: !skill.isSelected,
                };
                //If disableAll tag is true, disable all skills
            } else if (disableAll === true) {
                return {
                    ...skill,
                    isSelected: false,
                    childSkills: skill.childSkills.map((childSkill) => ({
                        ...childSkill,
                        isSelected: false,
                    })),
                };
            } else if (disableSpecial === true && skill.isSpecial === true) {
                return {
                    ...skill,
                    isSelected: false,
                    childSkills: skill.childSkills.map((childSkill) => ({
                        ...childSkill,
                        isSelected: false,
                    })),
                };
            }
            return skill;
        });
        setSkills(updatedSkills);
        //If updatedSkills are different than initialSkills, enable save button
        if (JSON.stringify(updatedSkills) !== JSON.stringify(initialSkills)) {
            setSkillsToSend(findChangedSkills(initialSkills, updatedSkills));
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    };

    const handleChildSkillSelection = (id: number) => {
        const parentSkill = skills.find((skill) => {
            return skill.childSkills.some((childSkill) => {
                return childSkill.id === id;
            });
        });

        const updatedSkills = skills.map((skill) => {
            if (parentSkill?.id === skill.id) {
                const updatedChildSkills = skill.childSkills.map(
                    (childSkill) => {
                        if (childSkill.id === id) {
                            return {
                                ...childSkill,
                                isSelected: !childSkill.isSelected,
                            };
                        }
                        return childSkill;
                    },
                );
                //If there are no child skills enabled, disable the parent skill
                const disableParent = updatedChildSkills.every(
                    (childSkill) => !childSkill.isSelected,
                );
                if (disableParent) {
                    return {
                        ...skill,
                        isSelected: false,
                        childSkills: updatedChildSkills,
                    };
                }
                return {
                    ...skill,
                    childSkills: updatedChildSkills,
                };
            }
            return skill;
        });
        setSkills(updatedSkills);
        //If updatedSkills are different than initialSkills, enable save button
        if (JSON.stringify(updatedSkills) !== JSON.stringify(initialSkills)) {
            setSkillsToSend(findChangedSkills(initialSkills, updatedSkills));
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    };

    //Helper for filtering out unchanged skills - optimization for API call
    const findChangedSkills = (initial: Skill[], updated: Skill[]): Skill[] => {
        const changedSkills = updated.filter(
            (skill) =>
                !initial.some(
                    (initialSkill) =>
                        JSON.stringify(initialSkill) === JSON.stringify(skill),
                ),
        );
        return changedSkills;
    };

    const styles = useStyles();
    return (
        <>
            <div className="SettingsMenuDefaults">
                <div>
                    <label>Conversation Context</label>
                    <Popover open={isPopoverOpen}>
                        <PopoverTrigger>
                            <Button
                                appearance="transparent"
                                icon={<InfoRegular />}
                                style={{
                                    marginRight: '10px',
                                    marginBottom: '2px',
                                }}
                                onMouseEnter={() => setIsPopoverOpen(true)}
                                onMouseLeave={() => setIsPopoverOpen(false)}
                            />
                        </PopoverTrigger>
                        <PopoverSurface
                            tabIndex={-1}
                            style={{
                                backgroundColor:
                                    'var(--colorNeutralBackground1)',
                                fontSize: '12px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                width: '200px',
                            }}
                        >
                            {
                                'Select the number of past messages to include in each new request. This helps give the model context for new user queries. Setting this number to 10 will include 10 user messages and 10 system responses.'
                            }
                        </PopoverSurface>
                    </Popover>
                </div>

                <div className="SettingsMenuChatHistoryFlex">
                    <div
                        onMouseUp={() => {
                            setIsSaveDisabled(false);
                            setUserPreferences((prevState) => ({
                                ...prevState,
                                chatHistoryLimit: updatedChatHistoryLimit,
                            }));
                        }}
                        onTouchEnd={() => {
                            setIsSaveDisabled(false);
                            setUserPreferences((prevState) => ({
                                ...prevState,
                                chatHistoryLimit: updatedChatHistoryLimit,
                            }));
                        }}
                    >
                        <Slider
                            value={updatedChatHistoryLimit}
                            min={0}
                            max={15}
                            onChange={(event, data) =>
                                setUpdatedChatHistoryLimit(data.value)
                            }
                        ></Slider>
                    </div>
                    <div style={{ minWidth: '20px' }}>
                        {updatedChatHistoryLimit}
                    </div>
                </div>
                <Divider />
                <label>Default Skills</label>
                <Dropdown
                    className={styles.dropdown}
                    placeholder={
                        skills &&
                        skills.length > 0 &&
                        skills.filter((s) => s.isSelected === true).length > 0
                            ? skills.filter((s) => s.isSelected === true)[0]
                                  .name + '...'
                            : 'Select a skill'
                    }
                    multiselect={true}
                    onOptionSelect={(event, data) => {
                        handleSkillSelection(Number(data.optionValue));
                    }}
                    selectedOptions={skills
                        .filter((s) => s.isSelected === true)
                        .map((s) => String(s.id))}
                >
                    <OptionGroup label="Basic">
                        {skills
                            .filter((s) => s.isSpecial === false)
                            .map((skill) => (
                                <Option key={skill.id} value={String(skill.id)}>
                                    {skill.name}
                                </Option>
                            ))}
                    </OptionGroup>
                    <OptionGroup label="Special">
                        {skills
                            .filter((s) => s.isSpecial === true)
                            .map((skill) => (
                                <Option key={skill.id} value={String(skill.id)}>
                                    {skill.name}
                                </Option>
                            ))}
                    </OptionGroup>
                </Dropdown>
                <Divider />
                <div
                    className="SettingsMenuSkillCardsFlex"
                    ref={skillCardsFlexRef}
                    style={{ maxHeight: '150px' }}
                >
                    {skills
                        .filter((s) => s.isSelected === true)
                        .map((skill) => (
                            <SkillCard
                                key={skill.id}
                                skillData={skill}
                                showSwitch={false}
                                handleSkillSelection={handleChildSkillSelection}
                                cardWidth="200px"
                                cardHeight={cardHeight ? cardHeight : ''}
                            />
                        ))}
                </div>
            </div>
        </>
    );
};

export default SettingsMenuAppearance;
