import {
    Button,
    Image,
    makeStyles,
    shorthands,
    Tooltip,
    Link,
} from '@fluentui/react-components';
import {
    ArrowExportLtr24Regular,
    ArrowExportRtlRegular,
    ShareIos24Regular,
    MoreVertical24Regular,
    Compose28Regular,
    PersonFeedbackRegular,
    ChatMultipleHeartFilled,
    PhoneChatFilled,
    AlertFilled,
} from '@fluentui/react-icons';
import { useEffect, useState } from 'react';

import {
    useGetThreadDetailsQuery,
    usePostThreadMutation,
} from 'Services/API/Aurora';

import useTermsAndConditions from '../../Hooks/useAlerts';
import useAppSelector from '../../Hooks/useAppSelector';
import '../../Styles/Header.Module.css';
import {
    setIsNewThread,
    setSelectedThreadId,
    unselectAllChatThreadsAction,
    unselectThreadId,
    updateLeftOpen,
    updateNewChatDialogOpenTrue,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';

const useStyles = makeStyles({
    leftButtons: {
        ...shorthands.margin('20px'),
    },
});

const HeaderContainer: React.FC = () => {
    const styles = useStyles();
    const leftPanelOpen = useAppSelector(
        (store) => store.userInterface.leftPanelOpen,
    );
    const banner = useAppSelector((store) => store.banner);
    const { showBanner } = useTermsAndConditions();
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    // get selected thread from store.
    const { data: selectedThread } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const [postThread, { data: postThreadResult }] = usePostThreadMutation();
    useEffect(() => {
        if (postThreadResult) {
            updateStore(setSelectedThreadId(postThreadResult.id));
        }
    }, [postThreadResult]);

    const isNewChatButtonDisabled =
        Boolean(selectedThread) && selectedThread?.messageCount === 0;

    const handleToggleSidebar = () => {
        updateStore(updateLeftOpen());
    };

    const handleToggleNewChat = () => {
        updateStore(unselectThreadId());
        updateStore(setIsNewThread(true));
        postThread();
        updateStore(updateNewChatDialogOpenTrue());
        updateStore(unselectAllChatThreadsAction());
    };

    const [isFeedbackHovered, setIsFeedbackHovered] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="HeaderContainer">
            <div className="InnerHeaderLeft">
                <Button
                    appearance="transparent"
                    icon={
                        leftPanelOpen ? (
                            <ArrowExportRtlRegular />
                        ) : (
                            <ArrowExportLtr24Regular />
                        )
                    }
                    className={styles.leftButtons}
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                    }}
                    onClick={handleToggleSidebar}
                ></Button>

                {!leftPanelOpen && (
                    <Button
                        appearance="transparent"
                        icon={<Compose28Regular />}
                        style={{
                            marginRight: '20px',
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                        }}
                        onClick={handleToggleNewChat}
                        disabled={isNewChatButtonDisabled}
                    ></Button>
                )}
                <Image
                    alt="Aurora Logo"
                    shape="circular"
                    src="/logo192.png"
                    height={40}
                    width={40}
                />
                <h2 style={{ paddingLeft: '15px' }}>Aurora</h2>
            </div>
            <div className="InnerHeaderRight">
                {/* <Button
                    appearance="subtle"
                    icon={<ShareIos24Regular />}
                ></Button>
                <Button
                    appearance="subtle"
                    className={styles.leftButtons}
                    icon={<MoreVertical24Regular />}
                ></Button> */}
                <a
                    href="https://publixo365.sharepoint.com/sites/PTAURORA/_layouts/15/listforms.aspx?cid=YjBiMjcxNWEtMTdjMi00ZDE1LWFmZDItYTdkMGEyZGEyNWU0&ct=1719331952289&or=Teams-HL&nav=NjZhOTc0YjktZTEyYi00YzNjLWE5YzctNzk4YmEzNjhhZmY4&LOF=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                >
                    <Tooltip
                        content="Share Feedback"
                        relationship="description"
                        withArrow
                    >
                        <Button
                            // appearance="subtle"
                            aria-label="Bold"
                            icon={
                                <ChatMultipleHeartFilled
                                    style={{
                                        // borderColor: 'var(--colorNeutralBackground)',
                                        color: isFeedbackHovered
                                            ? 'var(--colorBrandForeground1)'
                                            : 'var(--colorNeutralBackground)',
                                    }}
                                />
                            }
                            name="docMenu"
                            value="doclMenu"
                            style={{
                                borderColor: isFeedbackHovered
                                    ? 'var(--colorBrandForeground1)'
                                    : 'var(--colorNeutralBackground)',
                                borderRadius: '50%',
                                backgroundColor: 'transparent',
                            }}
                            // className={styles.leftButtons}
                            onMouseEnter={() => setIsFeedbackHovered(true)}
                            onMouseLeave={() => setIsFeedbackHovered(false)}
                        />
                    </Tooltip>
                </a>
                {banner.isHidden && Boolean(banner.message) && (
                    <Tooltip
                        content="View Notifications"
                        relationship="description"
                        withArrow
                    >
                        <Button
                            // appearance="subtle"
                            aria-label="Bold"
                            icon={
                                <AlertFilled
                                    style={{
                                        // borderColor: 'var(--colorNeutralBackground)',
                                        color: isHovered
                                            ? 'var(--colorBrandForeground1)'
                                            : 'var(--colorNeutralBackground)',
                                    }}
                                />
                            }
                            name="docMenu"
                            value="doclMenu"
                            style={{
                                borderColor: isHovered
                                    ? 'var(--colorBrandForeground1)'
                                    : 'var(--colorNeutralBackground)',
                                borderRadius: '50%',
                                backgroundColor: 'transparent',
                                margin: '0',
                            }}
                            className={styles.leftButtons}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={() => {
                                showBanner(banner.id);
                            }}
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default HeaderContainer;
