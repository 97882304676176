import {
    Toolbar,
    ToolbarGroup,
    ToolbarButton,
    ToolbarToggleButton,
    Tooltip,
    makeStyles,
    Button,
    useId,
    Toast,
    ToastTitle,
    useToastController,
    Toaster,
} from '@fluentui/react-components';
import {
    Flash32Regular,
    SendRegular,
    AttachFilled,
    RecordStopRegular,
    MicRegular,
    ArrowUpload16Filled,
    Cloud16Filled,
} from '@fluentui/react-icons';
import 'Styles/ChatThread.Module.css';
import { useRef } from 'react';

import { ErrorToast } from 'Components/ErrorToast';
import { PopoverMenu } from 'Components/PopoverMenu';
import { ERROR_MESSAGE } from 'Constants';
import useAppSelector from 'Hooks/useAppSelector';
import { useErrorToast } from 'Hooks/useErrorToast';
import {
    useGetThreadDetailsQuery,
    usePostAttachLocalFileToThreadMutation,
} from 'Services/API/Aurora';
import {
    updateRightOpen,
    openDocumentSearch,
    setToastMessage,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';
import { allowedExtensions, readFileAsBase64, validateFile } from 'Utils';

type MessageToolBarProps = {
    threadId?: number;
    isSendMessageButtonDisabled: boolean;
    onSendMessageButtonClick: () => void;
    resetTranscript: () => void;

    isRecording: boolean;
    toggleRecording: (isRecording?: boolean) => void;
    loadingSelectedDocument: boolean;
};
const useStyles = makeStyles({
    toolButton: {
        color: 'var(--colorNeutralForeground1)',
        ':hover': {
            color: 'var(--colorBrandForeground1)',
        },
    },
});

const MessageToolBar: React.FC<MessageToolBarProps> = (
    props: MessageToolBarProps,
) => {
    const styles = useStyles();
    const {
        threadId,
        isSendMessageButtonDisabled,
        onSendMessageButtonClick,
        toggleRecording,
        resetTranscript,
        loadingSelectedDocument,
    } = props;

    const { data: threadDetails } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const files = threadDetails?.attachments;
    const [attachLocalFile] = usePostAttachLocalFileToThreadMutation();
    const userInterface = useAppSelector((store) => store.userInterface);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleOpenLocalFileSelector = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ): Promise<void> => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];

            if (selectedFile) {
                try {
                    validateFile(selectedFile.name, files);
                } catch (e) {
                    if (e instanceof Error) {
                        updateStore(
                            setToastMessage({
                                title: e.message,
                                position: 'bottom',
                            }),
                        );
                        return;
                    }
                }
                attachLocalFile({
                    content: await readFileAsBase64(selectedFile),
                    contentType: selectedFile.type,
                    fileName: selectedFile.name,
                    threadId: threadId ?? 0,
                });
            }
        }
    };

    return (
        <Toolbar
            aria-label="with Tooltip"
            size="medium"
            style={{
                paddingLeft: '0px',
                paddingRight: '0px',
                justifyContent: 'space-between',
            }}
        >
            <ToolbarGroup>
                <Tooltip
                    content="Open Skill Menu"
                    relationship="description"
                    withArrow
                >
                    <Button
                        appearance="subtle"
                        aria-label="Bold"
                        className={styles.toolButton}
                        icon={<Flash32Regular />}
                        name="skillMenu"
                        value="skillMenu"
                        style={{
                            backgroundColor: 'transparent',
                            color: userInterface.rightPanelOpen
                                ? 'var(--colorBrandForeground1)'
                                : '',
                        }}
                        onClick={() => {
                            toggleRecording(false);
                            updateStore(updateRightOpen());
                        }}
                    />
                </Tooltip>
                <Tooltip
                    content={
                        files && files?.length > 1
                            ? 'Only two documents may be inferenced at a time.'
                            : 'Add Document'
                    }
                    relationship="description"
                    withArrow
                >
                    <PopoverMenu
                        labelA={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <ArrowUpload16Filled />
                                <div style={{ width: '8px' }} /> Upload from
                                this device
                            </div>
                        }
                        labelB={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Cloud16Filled color={'#31A4DE'} />
                                <div style={{ width: '8px' }} /> Attach cloud
                                files
                            </div>
                        }
                        handleSelectA={handleOpenLocalFileSelector}
                        handleSelectB={(event) => {
                            event.stopPropagation();
                            toggleRecording(false);
                            updateStore(openDocumentSearch());
                        }}
                        trigger={
                            <Button
                                appearance="subtle"
                                aria-label="Bold"
                                icon={<AttachFilled />}
                                name="docMenu"
                                value="doclMenu"
                                className={styles.toolButton}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: userInterface.documentSearchOpen
                                        ? 'var(--colorBrandForeground1)'
                                        : '',
                                }}
                                disabled={
                                    files != undefined && files.length > 1
                                }
                            />
                        }
                    ></PopoverMenu>
                </Tooltip>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ opacity: 0, width: 0 }}
                    onChange={handleFileChange}
                    accept={allowedExtensions.map((s) => `.${s}`).toString()}
                />
                <Tooltip content="Record" relationship="description" withArrow>
                    <Button
                        appearance="subtle"
                        aria-label="Bold"
                        icon={
                            props.isRecording ? (
                                <RecordStopRegular />
                            ) : (
                                <MicRegular />
                            )
                        }
                        name="record"
                        value="record"
                        style={{
                            backgroundColor: 'transparent',
                            color: props.isRecording ? 'red' : undefined,
                        }}
                        onClick={() => {
                            toggleRecording();
                        }}
                    />
                </Tooltip>
            </ToolbarGroup>
            <ToolbarGroup>
                <ToolbarButton
                    appearance="subtle"
                    aria-label="Bold"
                    icon={<SendRegular />}
                    name="send"
                    value="send"
                    onClick={() => {
                        toggleRecording(false);
                        resetTranscript();
                        onSendMessageButtonClick();
                    }}
                    style={{
                        backgroundColor: 'transparent',
                    }}
                    disabled={
                        isSendMessageButtonDisabled || loadingSelectedDocument
                    }
                />
            </ToolbarGroup>
        </Toolbar>
    );
};

export default MessageToolBar;
