import { useMsal } from '@azure/msal-react';

import * as GraphApiHelper from '../../Helpers/GraphApiHelper';
import * as MsalHelper from '../../Helpers/MsalHelper';
import { updateUserProfilePictureAction } from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import useAppSelector from '../useAppSelector';

const useUserProfile = () => {
    const { instance, accounts } = useMsal();
    const account = accounts[0];
    const getProfilePictureUrl = async (): Promise<void> => {
        try {
            const accessToken = await MsalHelper.acquireToken(
                instance,
                account,
                ['User.Read'],
                false,
            );
            const result = await GraphApiHelper.getProfilePicture(accessToken!);
            const { URL, webkitURL } = window;
            const url = URL || webkitURL;
            let profilePictureUrl = url.createObjectURL(result);
            updateStore(updateUserProfilePictureAction(profilePictureUrl));
        } catch (error) {
            console.error('error in getting profile picture url', error);
        }
    };

    const signOut = async (): Promise<void> => {
        await MsalHelper.signOut(instance);
    };

    return {
        getProfilePictureUrl,
        signOut,
    };
};

export default useUserProfile;
