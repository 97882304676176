import { z } from 'zod';

export interface ISkill {
    id: number;
    name: string;
    description: string;
    isSpecial: boolean;
    isSelected: boolean;
    childSkills: ISkill[];
    orderBy: number;
}

//Skill model
export const SkillModelSchema: z.ZodType<ISkill> = z.lazy(() =>
    z.object({
        id: z.number(),
        name: z.string(),
        description: z.string(),
        isSpecial: z.boolean(),
        isSelected: z.boolean(),
        childSkills: z.array(SkillModelSchema),
        orderBy: z.number(),
    }),
);

export const SkillModelArraySchema: z.ZodType<ISkill[]> =
    z.array(SkillModelSchema);

export type Skill = z.infer<typeof SkillModelSchema>;
