import { current } from '@reduxjs/toolkit';

import { ERROR_MESSAGE } from 'Constants';
import { setToastMessage } from 'Services/StateManagement/Actions';

import { auroraApi, AuroraEndpointBuilder } from '..';

export const removeThreadAttachment = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, { threadId: number; attachmentId: number }>({
        query: (args) => ({
            url: `/v1/Threads/RemoveThreadAttachment?threadId=${args.threadId}&attachmentId=${args.attachmentId}`,
            method: 'PATCH',
        }),
        async onQueryStarted(
            args: { threadId: number; attachmentId: number },
            { dispatch, queryFulfilled },
        ) {
            const request = dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadDetails',
                    args.threadId,
                    (draft) => {
                        if (!draft.attachments) {
                            return;
                        }
                        const deletedFileIndex = draft.attachments.findIndex(
                            (item) => item.id === args.attachmentId,
                        );
                        if (deletedFileIndex >= 0) {
                            draft.attachments[deletedFileIndex].isLoading =
                                true;
                        }
                    },
                ),
            );
            try {
                await queryFulfilled;
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreadDetails',
                        args.threadId,
                        (draft) => {
                            if (!draft.attachments) {
                                return;
                            }
                            const deletedFileIndex =
                                draft.attachments.findIndex(
                                    (item) => item.id === args.attachmentId,
                                );
                            if (deletedFileIndex >= 0) {
                                draft.attachments.splice(deletedFileIndex, 1);
                            }
                        },
                    ),
                );
            } catch {
                request.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.FileRemove,
                        body: '',
                        position: 'bottom',
                    }),
                );
            }
        },
    });
