import { Field, makeStyles, shorthands } from '@fluentui/react-components';
import { QueryStatus } from '@reduxjs/toolkit/query';
import React, { useEffect, useRef, useState } from 'react';
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import createSpeechServicesPonyfill from 'web-speech-cognitive-services';

import { ScreenPopover } from 'Components/ScreenPopover';
import { ERROR_MESSAGE } from 'Constants';
import { readFileAsBase64, validateFile } from 'Utils';

import EnableMicrophoneInstructions from '../../Components/EnableMicrophoneInstructions';
import MessageBar from '../../Components/MessageBar';
import MessageToolBar from '../../Components/MessageToolBar';
import { SelectedSkillsModel } from '../../Containers/SkillCardContainer';
import * as WebRequestHelper from '../../Helpers/WebRequestHelper';
import useAppSelector from '../../Hooks/useAppSelector';
import useAuroraApi from '../../Hooks/useAuroraApi';
import { Skill } from '../../Models/Skill';
import { StarterPrompt } from '../../Models/StarterPrompt';
import {
    useGetThreadDetailsQuery,
    usePatchThreadSkillsMutation,
    usePostAttachLocalFileToThreadMutation,
    usePostMessageMutation,
} from '../../Services/API/Aurora';
import {
    updateRightOpen,
    incrementThreadPage,
    initializeThreadPagination,
    disableChat,
    enableChat,
    setToastMessage,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import BannerContainer from '../BannerContainer';
import ChatMessagesContainer from '../ChatMessagesContainer';
import HeaderContainer from '../HeaderContainer';
import LoadNewChatCardContainer from '../LoadNewChatCardsContainer';
import NewChatCardsContainer from '../NewChatCardsContainer';
import SettingsMenuContainer from '../SettingsMenuContainer';
import SkeletonChatCards from '../SkeletonChatCards';

const chatContainerStyles = makeStyles({
    chatContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '0.3fr 2.6fr 0.3fr',
        gridAutoFlow: 'row',
        gridTemplateAreas: `
            "header"
            "chatArea"
            "messageBar"
        `,
        width: '100%',
        justifyContent: 'center', // aligns items horizontally in the center
        alignItems: 'center', // aligns items vertically in the center
    },
    header: {
        ...shorthands.gridArea('header'),
        alignItems: 'center',
    },
    messageBar: {
        ...shorthands.gridArea('messageBar'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '80%',
        justifySelf: 'center',
        paddingRight: '10px',
    },
    chatArea: {
        ...shorthands.gridArea('chatArea'),
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        justifySelf: 'center',
        overflowY: 'auto',
        maxHeight: '100%',
        minWidth: '80%',
        height: '100%',
    },
});
type ChatProps = {};

// some comments about chatArea styles. provided just for explanation. will remove that later.
// gridColumn: '1/4' style in chatArea ensures that this particular div occupies all 3 columns.
// alignItems: 'flex-end' style in chatArea ensures that the content is towards the bottom (need to toggle between 'center' and 'flex-end' when starting a new chat. Should be 'center' when no chat items, otherwise flex-end).
// there should be 1 child div inside chatArea div. chat content will be displayed inside this child div. if chat content is placed directly inside the chatArea div, then the content will be displayed in columns instead of rows.

const ChatContainer: React.FC<ChatProps> = () => {
    const { processApiRequest } = useAuroraApi();
    const [loadingSelectedDocument, setLoadingSelectedDocument] =
        useState(false);
    const scrollAnchorRef: React.LegacyRef<HTMLDivElement> = useRef(null);
    const isCreatingNewThread = useAppSelector(
        (store) => store.thread.isCreatingNewThread,
    );
    const isFetchingMessages = useAppSelector(
        (store) => store.thread.isFetchingMessages,
    );
    const canFetchMoreMessages = useAppSelector(
        (store) => store.thread.canFetchMoreMessages,
    );

    const fetchTokenAndInitializeSpeechRecognition = async () => {
        const response = await processApiRequest({
            path: `/v1/Speech/GetSpeechToken`,
            method: WebRequestHelper.RequestMethods.GET,
        });
        /*eslint-disable no-console*/
        const AUTHORIZATION_TOKEN = response.data.data;
        const REGION = process.env.REACT_APP_SPEECH_SERVICE_REGION;
        // console.log('AUTHORIZATION', AUTHORIZATION_TOKEN);
        const { SpeechRecognition: AzureSpeechRecognition } =
            createSpeechServicesPonyfill({
                credentials: {
                    region: REGION,
                    authorizationToken: AUTHORIZATION_TOKEN,
                },
            });

        SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
    };

    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
    const [
        isMicrophonePermissionPopupVisible,
        setIsMicrophonePermissionPopupVisible,
    ] = React.useState(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const scrollPositionBeforeLoadingMore = useRef<number | null>(0);
    const MAX_RECORDING_TIME_MILLISECONDS = 10000;

    const checkForMicrophonePermissions = async () => {
        navigator.permissions
            .query({ name: 'microphone' as PermissionName })
            .then(async (permissionStatus: PermissionStatus) => {
                if (permissionStatus.state === 'denied') {
                    setIsMicrophonePermissionPopupVisible(true);
                    permissionStatus.onchange = async () => {
                        if (permissionStatus.state !== 'denied') {
                            await startListening();
                            setRecord(true);
                        } else {
                            checkForMicrophonePermissions();
                        }
                    };
                } else {
                    await startListening();
                    setRecord(true);
                }
            });
    };

    const startListening = async () => {
        await SpeechRecognition.startListening({
            continuous: true,
            language: 'en-US',
        });
    };

    const skillsOpen = useAppSelector(
        (store) => store.userInterface.rightPanelOpen,
    );
    const [record, setRecord] = React.useState<boolean>(false);
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [isChatInProgress, setIsChatInProgress] = useState<boolean>(false);
    const [chatMessage, setChatMessage] = useState<string>('');
    const toggleResetTranscript = () => {
        resetTranscript();
    };

    useEffect(() => {
        if (record) {
            const timeoutId = setTimeout(() => {
                toggleRecording();
            }, MAX_RECORDING_TIME_MILLISECONDS);

            return () => clearTimeout(timeoutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcript, record]);

    const toggleRecording = async (toggleValue: boolean = !record) => {
        if (!toggleValue) {
            await SpeechRecognition.abortListening();
            setRecord(toggleValue);
        } else {
            await fetchTokenAndInitializeSpeechRecognition();
            resetTranscript();

            await checkForMicrophonePermissions();
        }
    };

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // New RTK Query Pattern
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const fetchPageNumber = useAppSelector(
        (store) => store.thread.pagination[threadId ?? 0],
    );
    const isRetryingPostMessage = useAppSelector((store) =>
        threadId ? store.thread.isRetrying[threadId] : false,
    );

    // initialize pagination for newly loaded thread
    useEffect(() => {
        if (threadId && fetchPageNumber === undefined) {
            updateStore(initializeThreadPagination({ threadId }));
        }
    }, [fetchPageNumber, threadId]);

    const isNewThread = useAppSelector((store) => store.thread.isNewThread);

    const {
        data: selectedThread,
        isFetching: isFetchingDetails,
        isLoading: isLoadingInitialDetails,
    } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const [patchThreadSkills, { isLoading: isUpdatingSkills }] =
        usePatchThreadSkillsMutation();

    const [
        postMessage,
        { isLoading: isPostingMessage, status: postMessageStatus },
    ] = usePostMessageMutation();

    const [attachLocalFile, { isLoading: isUploadingFile }] =
        usePostAttachLocalFileToThreadMutation();

    useEffect(() => {
        if (
            isUpdatingSkills ||
            isPostingMessage ||
            isUploadingFile ||
            isFetchingDetails
        ) {
            updateStore(disableChat());
            return;
        }
        updateStore(enableChat());
    }, [
        isUpdatingSkills,
        isPostingMessage,
        isUploadingFile,
        isFetchingDetails,
    ]);

    const styles = chatContainerStyles();

    // after loading new messages from scrolling, keep scrollbar in the same position
    useEffect(() => {
        if (
            scrollContainerRef.current &&
            scrollPositionBeforeLoadingMore.current
        ) {
            scrollContainerRef.current.scrollTop =
                scrollPositionBeforeLoadingMore.current;
        }
    }, [isFetchingMessages]);

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragPopoverOpen(false);
        if ((selectedThread?.attachments?.length ?? 0) >= 2) {
            updateStore(
                setToastMessage({
                    title: ERROR_MESSAGE.FileLimitReached,
                    position: 'bottom',
                }),
            );
            return;
        }
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const file = event.dataTransfer.files[0];
            try {
                validateFile(file.name, selectedThread?.attachments);
            } catch (e) {
                if (e instanceof Error) {
                    updateStore(
                        setToastMessage({
                            title: e.message,
                            position: 'bottom',
                        }),
                    );
                    return;
                }
            }
            attachLocalFile({
                content: await readFileAsBase64(file),
                contentType: file.type,
                fileName: file.name,
                threadId: threadId ?? 0,
            });
        }
    };

    //Helper functions
    //Filters out unchanged skills so no redundant API calls are made
    function filterUnchangedSkills(
        newSkills: SelectedSkillsModel[],
        oldSkills: SelectedSkillsModel[],
    ): SelectedSkillsModel[] {
        return newSkills.filter((newSkill) => {
            const oldSkill = oldSkills.find(
                (oldSkill) => oldSkill.skillid === newSkill.skillid,
            );
            if (oldSkill!.isSelected === newSkill.isSelected) {
                return false;
            }
            return true;
        });
    }
    //Flattens the skills array so it can be sent as a proper setThreadRequestModel array
    function flattenSkills(skills: Skill[]): SelectedSkillsModel[] {
        return skills.reduce((acc: SelectedSkillsModel[], skill) => {
            const flattenedSkill = {
                skillid: skill.id,
                isSelected: skill.isSelected,
            } as SelectedSkillsModel;

            if (skill.childSkills) {
                return acc.concat(
                    flattenedSkill,
                    flattenSkills(skill.childSkills),
                );
            }
            return acc.concat(flattenedSkill);
        }, []);
    }

    const sendStarterMessage = async (prompt: StarterPrompt) => {
        setIsChatInProgress(true);
        //Create a skills array for updating store
        const newSkillsForStore = selectedThread?.skills?.map((s) => {
            if (s.id === prompt.skillId) {
                const updatedChildSkills = s.childSkills?.map((cs) => {
                    return { ...cs, isSelected: true };
                });
                return {
                    ...s,
                    isSelected: true,
                    childSkills: updatedChildSkills,
                };
            }
            const updatedChildSkills = s.childSkills?.map((cs) => {
                return { ...cs, isSelected: false };
            });
            return { ...s, isSelected: false, childSkills: updatedChildSkills };
        });
        //Flatten the skills arrays so it can be filtered easier for API calls
        const newSkillsFlattened: SelectedSkillsModel[] = flattenSkills(
            newSkillsForStore!,
        );
        const oldSkillsFlattened: SelectedSkillsModel[] = flattenSkills(
            selectedThread?.skills ?? [],
        );

        //Filter out the unchanged skills
        const newSkillsForAPI: SelectedSkillsModel[] = filterUnchangedSkills(
            newSkillsFlattened,
            oldSkillsFlattened,
        );

        //Update the changed skills in the API
        await patchThreadSkills({
            threadId: selectedThread!.id,
            setSkills: newSkillsForAPI,
            setLocalSkills: newSkillsForStore ?? [],
        });
        //Send the message
        sendChatMessage(prompt.promptMessage);
    };

    const showChatsCardsContainer = () => {
        if (isCreatingNewThread) {
            return <LoadNewChatCardContainer />;
        }
        if (!selectedThread || selectedThread?.messageCount === 0) {
            return (
                <NewChatCardsContainer
                    sendStarter={sendStarterMessage}
                    threadId={threadId}
                />
            );
        }

        if (!isNewThread && (isFetchingMessages || isLoadingInitialDetails)) {
            return <SkeletonChatCards />;
        }

        return <LoadNewChatCardContainer />;
    };

    const sendChatMessage = (starterMessage?: string) => {
        //If no skills are selected and no files attached, do not send the message
        if (
            !selectedThread?.skills?.some((s) => s.isSelected) &&
            !starterMessage &&
            (selectedThread?.attachments ?? []).length === 0
        ) {
            updateStore(
                setToastMessage({
                    title: ERROR_MESSAGE.NoSkillsSelected,
                    position: 'bottom',
                }),
            );
            if (!skillsOpen) {
                updateStore(updateRightOpen());
            }
            return;
        }

        // const currentMessagesCount = selectedThread!.chatResponses?.length;
        setIsChatInProgress(true);
        setChatMessage('');

        try {
            handleScrollNewMessage();

            postMessage({
                threadId: threadId ?? 0,
                message: starterMessage ?? chatMessage.trim(),
                retryReferenceId: uuidv4(),
            });

            handleScrollNewMessage();
        } catch (error: any) {
            console.error('error', error);
        } finally {
            setIsChatInProgress(false);
        }
    };

    // show messages container only if the thread details have been fetched
    const showMessageBarContainer = () => {
        return (
            <div className={styles.messageBar}>
                <Field
                    size="medium"
                    style={{
                        marginTop: '3px',
                        paddingTop: '16px',
                        width: windowWidth < 900 ? '90vw' : '100%',
                        marginLeft: windowWidth < 900 ? '10px' : '0px',
                    }}
                >
                    <MessageBar
                        message={chatMessage}
                        threadId={selectedThread?.id}
                        toggleRecording={toggleRecording}
                        isRecording={record}
                        onChange={(value) => setChatMessage(value)}
                        resetTranscript={toggleResetTranscript}
                        transcript={transcript}
                        onEnterKeyPressed={(isShiftKeyPressed: boolean) => {
                            if (!isShiftKeyPressed) {
                                sendChatMessage();
                            }
                        }}
                        isFetchingThreadDetails={isFetchingDetails}
                        setLoadingSelectedDocument={(loading) =>
                            setLoadingSelectedDocument(loading)
                        }
                        loadingSelectedDocument={loadingSelectedDocument}
                    />
                    <MessageToolBar
                        threadId={selectedThread?.id}
                        toggleRecording={toggleRecording}
                        isRecording={record}
                        resetTranscript={toggleResetTranscript}
                        isSendMessageButtonDisabled={
                            isChatInProgress || chatMessage.trim().length === 0
                        }
                        onSendMessageButtonClick={sendChatMessage}
                        loadingSelectedDocument={loadingSelectedDocument}
                    />
                </Field>
            </div>
        );
    };

    // when scroll bar hits the top, load more messages
    const handleScroll = () => {
        if (scrollContainerRef.current) {
            var { scrollTop, scrollHeight, clientHeight } =
                scrollContainerRef.current;

            if (Math.abs(scrollTop) + clientHeight + 5 > scrollHeight) {
                // trigger loading more threads
                if (threadId && !isFetchingMessages && canFetchMoreMessages) {
                    scrollPositionBeforeLoadingMore.current =
                        scrollContainerRef.current
                            ? scrollContainerRef.current.scrollTop
                            : null;
                    updateStore(incrementThreadPage({ threadId }));
                }
            }
        }
    };

    // when a new message is sent, scroll to the bottom
    const handleScrollNewMessage = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
            scrollPositionBeforeLoadingMore.current = 0;
        }
    };

    useEffect(() => {
        if (postMessageStatus === QueryStatus.fulfilled) {
            handleScrollNewMessage();
        }
    }, [postMessageStatus]);

    // when switching threads, scroll to the bottom
    useEffect(() => {
        handleScrollNewMessage();
    }, [threadId]);

    // event listener for the scroll event to the DrawerBody
    useEffect(() => {
        const currentDrawerBody = scrollContainerRef.current;

        if (currentDrawerBody) {
            currentDrawerBody.removeEventListener('scroll', handleScroll);
            currentDrawerBody.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentDrawerBody) {
                currentDrawerBody.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        threadId,
        isFetchingMessages,
        scrollPositionBeforeLoadingMore,
        scrollContainerRef,
    ]);

    const [isDragPopoverOpen, setIsDragPopoverOpen] = useState(false);
    // Need this to prevent a flickering bug (see https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element)
    const enterTarget = useRef<EventTarget | null>(null);

    return (
        <div
            className={styles.chatContainer}
            style={{
                height: windowHeight,
                background: 'var(--colorNeutralBackground5)',
            }}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => {
                enterTarget.current = e.target;
                e.preventDefault();
                setIsDragPopoverOpen(true);
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                if (e.target === enterTarget.current) {
                    e.preventDefault();
                    setIsDragPopoverOpen(false);
                }
            }}
        >
            <ScreenPopover content={<></>} isOpen={isDragPopoverOpen} />
            <div className={styles.header}>
                <BannerContainer />
                <HeaderContainer />
            </div>
            <div
                className={styles.chatArea + ' custom-scrollbar'}
                style={{
                    // gridColumn: '1/4',
                    alignItems: 'flex-end',
                    width: windowWidth < 900 ? '90vw' : '80%',
                    zIndex: 9,
                }}
                ref={scrollContainerRef}
                // onScroll={handleScroll}
            >
                {selectedThread?.messageCount &&
                !isLoadingInitialDetails &&
                !isCreatingNewThread ? (
                    <ChatMessagesContainer fetchPageNumber={fetchPageNumber} />
                ) : (
                    showChatsCardsContainer()
                )}
                <div ref={scrollAnchorRef}></div>
            </div>
            {showMessageBarContainer()}
            <SettingsMenuContainer />
            {isMicrophonePermissionPopupVisible && (
                <EnableMicrophoneInstructions
                    onPopupClosed={() => {
                        setIsMicrophonePermissionPopupVisible(false);
                    }}
                />
            )}
        </div>
    );
};

export default ChatContainer;
