import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { GlobalState } from '../../../Models/GlobalState';

const initialState: GlobalState = {
    sessionId: uuidv4(),
    accessToken: '',
    isHostedInTeams: false,
};

export const GlobalStateSlice = createSlice({
    name: 'globalState',
    initialState: initialState,
    reducers: {
        setIsHostedInTeamsAction: (state) => {
            return { ...state, isHostedInTeams: true };
        },
        setAccessToken: (state, action) => {
            return { ...state, accessToken: action.payload };
        },
    },
});
