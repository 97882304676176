import axios, { AxiosResponse } from 'axios';

export enum RequestMethods {
    'GET' = 'GET',
    'POST' = 'POST',
    'PUT' = 'PUT',
    'DELETE' = 'DELETE',
    'PATCH' = 'PATCH',
}

type WebRequestParameters = {
    url: string;
    method: RequestMethods;
    headers: Record<string, string>;
    data?: any;
    responseType?: any;
};

type WebResponse = {
    data?: any;
    contentType?: string;
    statusCode?: number;
    headers: Record<string, string>;
};

type WebRequestError = {
    name: string;
    message?: string;
    data?: any;
    statusCode?: number;
    headers: Record<string, string>;
    stack?: string;
};

export async function processWebRequest(
    requestParameters: WebRequestParameters,
): Promise<WebResponse> {
    try {
        const response: AxiosResponse = await axios.request({
            url: requestParameters.url,
            method: requestParameters.method,
            headers: requestParameters.headers,
            data: requestParameters.data,
            responseType: requestParameters.responseType,
        });
        return {
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
            statusCodeDescription: response.statusText,
            contentType: response.headers['content-type'],
        } as WebResponse;
    } catch (error: any) {
        let message: string =
            error.message ?? 'An error occurred while processing request.';
        let data: any | undefined;
        let statusCode: number = 0;
        let headers: any | undefined;
        if (error.response) {
            data = error.response.data;
            statusCode = error.response.status ?? 0;
            headers = error.response.headers;
        }
        throw {
            name: 'WebRequestError',
            message: message,
            data: data,
            statusCode: statusCode,
            headers: headers,
            stack: error.stack,
        } as WebRequestError;
    }
}
