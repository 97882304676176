import { z } from 'zod';

interface IStarterPrompt {
    id: number;
    promptMessage: string;
    promptTitle: string;
    order: number;
    skillId: number;
    skillName: string;
}

//Skill model
const StarterPromptSchema: z.ZodType<IStarterPrompt> = z.object({
    id: z.number(),
    promptMessage: z.string(),
    promptTitle: z.string(),
    order: z.number(),
    skillId: z.number(),
    skillName: z.string(),
});

export const StarterPromptArraySchema: z.ZodType<IStarterPrompt[]> =
    z.array(StarterPromptSchema);

export type StarterPrompt = z.infer<typeof StarterPromptSchema>;
