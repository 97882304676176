import {
    StarterPrompt,
    StarterPromptArraySchema,
} from '../../Models/StarterPrompt';
import { validateDataAgainstSchema } from '../../Utils';
import useAuroraApi from '../useAuroraApi';

//Helper for validating schema
const checkForInvalidData = (data: StarterPrompt[] | null): void => {
    if (!data) {
        throw new Error('Data is null');
    }
    // Validate the data against the schema
    const isValidSchema = validateDataAgainstSchema<StarterPrompt[]>(
        data,
        StarterPromptArraySchema,
    );
    // If the data does not match the schema, throw an error
    if (!isValidSchema) {
        throw new Error('Data does not match schema');
    }
};

const useStarterPrompts = () => {
    //Helper for API calls
    const { processApiRequest } = useAuroraApi();

    //Get starter prompts
    const getStarterPrompts = async (
        isDocumentInferencing: boolean,
    ): Promise<StarterPrompt[]> => {
        // make API call to fetch the threads.
        try {
            const basePath = '/v1/Prompts/GetPrompts';
            const path = isDocumentInferencing
                ? `${basePath}?DocumentInferencing=true`
                : basePath;
            const response = await processApiRequest({
                //todo: update the path to the correct endpoint.
                path: path,
            });
            // handle the response here
            const data = response.data.data as StarterPrompt[];
            checkForInvalidData(data);
            //Maybe update prompts in store?
            return data;
        } catch (error) {
            console.error(
                'Error occurred while processing API request: getStarterPrompts',
                error,
            );
            throw error;
        }
    };
    return { getStarterPrompts };
};

export default useStarterPrompts;
