import {
    makeStyles,
    shorthands,
    Text,
    tokens,
    Subtitle1,
    Card,
    CardHeader,
    Image,
    Skeleton,
    SkeletonItem,
} from '@fluentui/react-components';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import chatData from '../../Assets/newChatData.json';
import { updateNewChatDialogOpenFalse } from '../../Services/StateManagement/Actions';
type ChatCardProps = {};

const useStyles = makeStyles({
    main: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // This creates three columns
        width: '100%', // Take full width
        '@media(max-width: 1000px)': {
            gridTemplateColumns: 'repeat(2, 1fr)', // Two columns for medium/small screens
        },
    },
    card: {
        width: '95%',

        height: '130px',
        marginBottom: '12px',
        marginRight: '6px',
        marginLeft: '6px',
        paddingBottom: '8px',

        backgroundColor: tokens.colorNeutralBackground4,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground3,
            cursor: 'pointer',
        },
    },

    title: {
        ...shorthands.margin(0, 0, '15px'),
        textAlign: 'center', // center align the title text
        marginBottom: '40px',
    },

    text: {
        ...shorthands.margin(0),
    },

    skillName: {
        ...shorthands.margin(0),
        justifyContent: 'flex-end',
    },
});

const Title = ({ children }: React.PropsWithChildren<{}>) => {
    const styles = useStyles();

    return (
        <Subtitle1 as="h4" block className={styles.title}>
            {children}
        </Subtitle1>
    );
};
const LoadNewChatCardContainer: React.FC<ChatCardProps> = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const handleToggleNewChat = () => {
        dispatch(updateNewChatDialogOpenFalse());
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center', // Center horizontally
                width: '100%', // Take full width to allow centering
                height: '100vh', // Take full height to allow centering
            }}
        >
            <Image
                alt="Aurora Logo"
                src="/logo192.png"
                shape="circular"
                height={70}
                width={70}
                style={{ marginBottom: '20px' }}
            />

            <Title>How can I help you today?</Title>
            <div className={styles.main}>
                {chatData.map((chat) => (
                    <Card
                        key={chat.id}
                        className={styles.card}
                        onClick={handleToggleNewChat}
                        style={{
                            borderRadius: '10px',
                        }}
                    >
                        <Skeleton animation="pulse">
                            <SkeletonItem
                                style={{
                                    width: '70px',
                                    backgroundColor:
                                        'var(--colorNeutralBackground2)',
                                    marginTop: '10px',
                                }}
                            />
                        </Skeleton>
                        <Skeleton animation="pulse">
                            <SkeletonItem
                                style={{
                                    backgroundColor:
                                        'var(--colorNeutralBackground2)',
                                    marginTop: '10px',
                                }}
                            />
                        </Skeleton>{' '}
                        <Skeleton animation="pulse">
                            <SkeletonItem
                                style={{
                                    backgroundColor:
                                        'var(--colorNeutralBackground2)',
                                    marginTop: '10px',
                                }}
                            />
                        </Skeleton>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default LoadNewChatCardContainer;
