import { ErrorMessage } from './Models/ErrorMessage';

export enum RequestHeaders {
    Authorization = 'Authorization',
    ContentType = 'Content-Type',
    SessionId = 'x-aur-session-id',
    RequestId = 'x-aur-client-request-id',
}

export const ERROR_REPORTING_EMAIL = 'aiic@publix.com';

export const ENCODED_USER_ERROR_SUBJECT = encodeURIComponent(
    'AuroraWeb User Error Report',
);

export const ENCODED_USER_ERROR_BODY = (errorMessage: ErrorMessage) =>
    encodeURIComponent(
        `RequestId: ${errorMessage?.requestId}
    
Please provide any relevant details:

`,
    );

export const ERROR_MESSAGE = {
    FileLimitReached: 'You cannot upload more than 2 files.',
    Reaction: 'Error submitting reaction.',
    ThreadUpdate: 'Error updating thread.',
    FileUpload: 'Error uploading file.',
    FileRemove: 'Error removing attached file.',
    ThreadCreate: 'Error creating new thread.',
    ThreadsGet: 'Error retrieving threads. Please try refreshing the page.',
    ThreadDetailsGet:
        'Error retrieving thread details. Please try refreshing the page.',
    ThreadMessagesGet:
        'Error retrieving thread messages. Please try refreshing the page.',
    ThreadDelete: 'Error deleting thread.',
    ContextUpdate: 'Error updating context.',
    SkillsUpdate: 'Error updating skills.',
    MessagePost: 'Error submitting message, please try again.',
    NoSkillsSelected: 'You must select at least 1 skill on the right.',
    TryAgain: 'Please try again.',
};
