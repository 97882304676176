import {
    Dialog,
    DialogSurface,
    DialogContent,
    DialogBody,
    useId,
    Toaster,
    Toast,
    ToastBody,
    ToastTitle,
    useToastController,
    Button,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import '../../Styles/SettingsMenu.Module.css';

import LoadingSettingsMenuDefaults from '../../Components/LoadingSettingsMenuDefaults';
import SettingsMenuDefaults from '../../Components/SettingsMenuDefaults/SettingsMenuDefaults';
import useAppSelector from '../../Hooks/useAppSelector';
import useUser from '../../Hooks/useUser/index';
import useUserSkills from '../../Hooks/useUserSkills';
import { ISkill, Skill } from '../../Models/Skill';
import { User } from '../../Models/User';
import {
    useGetThreadDetailsQuery,
    usePatchThreadChatHistoryLimitMutation,
    usePatchThreadSkillsMutation,
} from '../../Services/API/Aurora';
import {
    updateSettingsDialogOpen,
    updateUserSkills,
    updateUserAction,
    updateNewChatDialogOpenTrue,
    unselectAllChatThreadsAction,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';

interface SettingsMenuContainerProps {}

//Styles
const useStyles = makeStyles({
    dialogSurface: {
        ...shorthands.padding('0px'),
        '@media(max-width: 850px)': {
            // for screens smaller than 850px
            minWidth: '100%', // use a fixed width
            height: '100%',
        },
        '@media(min-width: 851px)': {
            // for screens larger than 850px
            minWidth: '700px', // use a fixed width
            height: '70%',
        },
    },
    dialogBody: {
        height: '100%',
        maxHeight: '100%',
        ...shorthands.padding('0px'),
    },
    dialogContent: {
        height: '100%',
        ...shorthands.padding('0px'),
    },
});

const SettingsMenuContainer: React.FC<SettingsMenuContainerProps> = () => {
    const styles = useStyles();
    const isDialogOpen = useAppSelector(
        (store) => store.userInterface.settingsDialogOpen,
    );
    //Hooks for fetching user/userSkill data
    const { getUser, updateUser } = useUser();
    const { getUserSkills, updateSkills } = useUserSkills();
    //Get user data from the store
    const initialUser: User = useAppSelector((store) => store.user);
    const initialSkills: Skill[] = useAppSelector((store) => store.userSkills);
    //Get selected thread from the store

    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const { data: selectedThread } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const [patchThreadSkills] = usePatchThreadSkillsMutation();
    const [patchThreadChatLimit] = usePatchThreadChatHistoryLimitMutation();

    //User state
    const [userPreferences, setUserPreferences] =
        React.useState<User>(initialUser);

    //State for user skills
    const [skills, setSkills] = React.useState<Skill[]>(initialSkills);
    //State for storing the skills to be sent in the API request
    const [skillsToSend, setSkillsToSend] = React.useState<Skill[]>([]);

    //State for save button usability
    const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = React.useState<boolean>(false);
    const [isSaved, setIsSaved] = React.useState<boolean>(false);

    // Fetch user data when the component mounts
    React.useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true);
            try {
                const user = await getUser();
                if (user === undefined) {
                    console.error('User is undefined');
                    return;
                }
                setUserPreferences(user);
            } catch (error) {
                console.error('Error occurred while fetching user data', error);
            } finally {
                setIsLoading(false);
            }
        };
        const fetchSkills = async () => {
            setIsLoading(true);
            try {
                const initialSkills: Skill[] = (await getUserSkills()) || [];
                setSkills(initialSkills);
            } catch (error) {
                console.error(
                    'Error occurred while fetching user skills',
                    error,
                );
            } finally {
                setIsLoading(false);
            }
        };
        //Only fetch when dialog is opening
        if (!isDialogOpen) return;

        fetchUser();
        fetchSkills();
        setIsSaveDisabled(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDialogOpen]);

    //Handlers
    const handleSave = async () => {
        setIsLoadingSave(true);
        try {
            //Call API to update
            await updateSkills(skillsToSend);
            await updateUser(userPreferences);
            //Update store/state
            updateStore(updateUserSkills(skills));
            updateStore(updateUserAction(userPreferences));
            setIsSaveDisabled(true);

            //If a new chat is open, update it to follow the new defaults
            if (selectedThread && selectedThread.messageCount === 0) {
                patchThreadSkills({
                    threadId: threadId ?? 0,
                    setSkills: skills.map((s) => ({
                        skillid: s.id,
                        isSelected: s.isSelected,
                    })),
                    setLocalSkills: skills.map(
                        (s): ISkill => ({
                            id: s.id,
                            isSelected: s.isSelected,
                            name: s.name,
                            description: s.description,
                            isSpecial: s.isSpecial,
                            childSkills: s.childSkills,
                            orderBy: s.orderBy,
                        }),
                    ),
                });
                patchThreadChatLimit({
                    threadId: threadId ?? 0,
                    chatHistoryLimit: userPreferences.chatHistoryLimit,
                });
                updateStore(updateNewChatDialogOpenTrue());
                updateStore(unselectAllChatThreadsAction());
            }
            // Set isSaved to true when the save is successful
            setIsSaved(true);
            setTimeout(() => setIsSaved(false), 2000);
            //Close the dialog
            updateStore(updateSettingsDialogOpen());
            notify(true);
        } catch (error) {
            console.info('Error occurred while saving settings', error);
            //Lets have a toast! (but not a good one, a bad one)
            notify(false);
        } finally {
            setIsLoadingSave(false); // Set loading to false when the process is complete or if an error occurs
        }
    };

    //Toasters
    const toasterId = useId('cheers');
    const { dispatchToast } = useToastController(toasterId);
    const notify = (message: boolean) => {
        message
            ? dispatchToast(
                  <Toast>
                      <ToastTitle>Settings saved successfully</ToastTitle>
                  </Toast>,
                  { intent: 'success' },
              )
            : dispatchToast(
                  <Toast>
                      <ToastTitle>Oops, something went wrong</ToastTitle>
                      <ToastBody>
                          There was an error saving your settings, please try
                          again
                      </ToastBody>
                  </Toast>,
                  { intent: 'error' },
              );
    };
    const notify2 = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>Loading...</ToastTitle>
            </Toast>,
            { intent: 'success' },
        );
    };

    return (
        <>
            <Toaster toasterId={toasterId} />
            <Dialog
                modalType="modal"
                open={isDialogOpen}
                onOpenChange={() => updateStore(updateSettingsDialogOpen())}
            >
                <DialogSurface className={styles.dialogSurface}>
                    <DialogBody className={styles.dialogBody}>
                        <DialogContent className={styles.dialogContent}>
                            {!isLoading ? (
                                <div className="SettingsMenuContainer">
                                    <div className="SettingsMenuTopNav">
                                        <Text size={500}>Settings</Text>
                                        <Button
                                            icon={<Dismiss24Regular />}
                                            appearance="subtle"
                                            onClick={() =>
                                                updateStore(
                                                    updateSettingsDialogOpen(),
                                                )
                                            }
                                        />
                                    </div>
                                    {/* <LoadingSettingsMenuDefaults /> */}
                                    <SettingsMenuDefaults
                                        userPreferences={userPreferences}
                                        setUserPreferences={setUserPreferences}
                                        skills={skills}
                                        initialSkills={initialSkills}
                                        setSkillsToSend={setSkillsToSend}
                                        setSkills={setSkills}
                                        setIsSaveDisabled={setIsSaveDisabled}
                                    />

                                    <div className="SettingsMenuFooterNav">
                                        <Button
                                            onClick={async () => {
                                                await handleSave();
                                            }}
                                            appearance="primary"
                                            disabled={
                                                isSaveDisabled || isLoadingSave
                                            } // Disable the button when saving
                                        >
                                            {isSaved
                                                ? 'Saved'
                                                : isLoadingSave
                                                  ? 'Saving...'
                                                  : 'Save'}
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="SettingsMenuContainer">
                                    <div className="SettingsMenuTopNav">
                                        <Text size={500}>Settings</Text>
                                        <Button
                                            icon={<Dismiss24Regular />}
                                            appearance="subtle"
                                            onClick={() =>
                                                updateStore(
                                                    updateSettingsDialogOpen(),
                                                )
                                            }
                                        />
                                    </div>

                                    <LoadingSettingsMenuDefaults />
                                    <div className="SettingsMenuFooterNav">
                                        <Button
                                            onClick={async () => {
                                                await handleSave();
                                            }}
                                            appearance="primary"
                                            disabled={
                                                isSaveDisabled || isLoadingSave
                                            } // Disable the button when saving
                                        >
                                            {isSaved
                                                ? 'Saved'
                                                : isLoadingSave
                                                  ? 'Saving...'
                                                  : 'Save'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};

export default SettingsMenuContainer;
