import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
    ChatThreadModel,
    ChatMessagesPagination,
    ChatResponseApiResource,
    MessageReactionType,
    SelectedFile,
} from '../../../Models/ChatThread';
import { Skill } from '../../../Models/Skill';

const initialState: ChatThreadModel[] = [];

export const ChatThreadsSlice = createSlice({
    name: 'chatThreads',
    initialState: initialState,
    reducers: {
        setChatThreadsAction: (
            state,
            action: PayloadAction<ChatThreadModel[]>,
        ) => {
            const allThreads = [...state];
            const newThreads = [...action.payload];
            // TODO: this is gonna get slow if there are many threads
            // we should use createEntityAdapter to normalize into an object
            newThreads.forEach((thread) => {
                if (allThreads.findIndex((c) => c.id === thread.id) < 0) {
                    allThreads.unshift(thread);
                }
            });
            return [...allThreads];
        },
        newChatThreadAction: (
            state,
            action: PayloadAction<ChatThreadModel>,
        ) => {
            const existingThreads = unselectAllThreads(state);
            const newThread = structuredClone(action.payload);
            newThread.isSelected = true;
            newThread.isHidden = true;
            const updatedState = [newThread, ...existingThreads];
            return updatedState;
        },
        editChatThreadAction: (
            state,
            action: PayloadAction<ChatThreadModel>,
        ) => {
            const updatedState = cloneState(state);
            const chatThread = action.payload;
            const matchingItemIndex = updatedState.findIndex(
                (c: ChatThreadModel) => c.id == chatThread.id,
            );
            if (matchingItemIndex >= 0) {
                chatThread.isSelected =
                    updatedState[matchingItemIndex].isSelected;
                updatedState.splice(matchingItemIndex, 1, chatThread);
            }
            return updatedState;
        },
        updateChatThreadDetailsAction: (
            state,
            action: PayloadAction<ChatThreadModel>,
        ) => {
            const updatedState = cloneState(state);
            const chatThread = action.payload;
            const matchingItemIndex = updatedState.findIndex(
                (c: ChatThreadModel) => c.id == chatThread.id,
            );
            if (matchingItemIndex >= 0) {
                unselectAllThreads(updatedState);
                updatedState.splice(matchingItemIndex, 1, chatThread);
            }
            return updatedState;
        },
        deleteChatThreadAction: (state, action: PayloadAction<number>) => {
            const updatedState = cloneState(state);
            const matchingItemIndex = updatedState.findIndex(
                (c: ChatThreadModel) => c.id == action.payload,
            );
            if (matchingItemIndex >= 0) {
                updatedState.splice(matchingItemIndex, 1);
            }
            return updatedState;
        },
        selectChatThreadAction: (state, action: PayloadAction<number>) => {
            const updatedState = cloneState(state);
            const matchingItemIndex = updatedState.findIndex(
                (c: ChatThreadModel) => c.id == action.payload,
            );
            if (matchingItemIndex >= 0) {
                updatedState.forEach((c: ChatThreadModel) => {
                    c.isSelected = false;
                });
                updatedState[matchingItemIndex].isSelected = true;
            }
            return updatedState;
        },
        updateChatThreadTitleAction: (
            state,
            action: PayloadAction<{ threadId: number; title: string }>,
        ) => {
            const { threadId, title } = action.payload;
            const matchingItemIndex = state.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                state[matchingItemIndex].title = title;
                state[matchingItemIndex].isHidden = false;
            }
            console.info('state', state);
        },
        unselectAllChatThreadsAction: (state) => {
            const updatedThreads = unselectAllThreads(state);
            return updatedThreads;
        },

        addChatResponsesToChatThreadAction: (
            state,
            action: PayloadAction<{
                threadId: number;
                chatResponses: ChatResponseApiResource[];
                pagination: ChatMessagesPagination;
            }>,
        ) => {
            const { threadId, chatResponses, pagination } = action.payload;
            const matchingItemIndex = state.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                const chatThread = state[matchingItemIndex];
                chatThread.areChatsFetched = true;
                const existingChatResponses = chatThread.chatResponses ?? [];

                // Filter out chatResponses that already exist in existingChatResponses
                const newChatResponses = chatResponses.filter(
                    (newChat) =>
                        !existingChatResponses.some(
                            (existingChat) => existingChat.id === newChat.id,
                        ),
                );

                existingChatResponses.unshift(...newChatResponses);
                chatThread.chatResponses = existingChatResponses;
                chatThread.pagination = pagination;
            }
        },

        addChatResponseToChatThreadAction: (
            state,
            action: PayloadAction<ChatResponseApiResource>,
        ) => {
            const chatResponse = action.payload;
            const matchingItemIndex = state.findIndex(
                (c: ChatThreadModel) => c.id == chatResponse.threadId,
            );

            if (matchingItemIndex >= 0) {
                const chatThread = state[matchingItemIndex];
                const chatResponses = chatThread.chatResponses ?? [];
                chatResponses.push(chatResponse);
                chatThread.chatResponses = chatResponses;
                chatThread.timeStamp = new Date().toISOString(); //move this thread to the top
            }
        },
        // COME BACK TO THIS
        addChatResponsesToChatThreadActionERM: (
            state,
            action: PayloadAction<{
                threadId: number;
                chatResponses: ChatResponseApiResource[];
            }>,
        ) => {
            const { threadId, chatResponses } = action.payload;
            const matchingItemIndex = state.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                const chatThread = state[matchingItemIndex];
                chatThread.areChatsFetched = true;

                // Replace the existing chatResponses with the new ones
                chatThread.chatResponses = chatResponses;
            }
        },

        setChatHistoryLimitAction: (
            state,
            action: PayloadAction<{
                threadId: number;
                chatHistoryLimit: number;
            }>,
        ) => {
            const { threadId, chatHistoryLimit } = action.payload;
            const updatedState = cloneState(state);
            const matchingItemIndex = updatedState.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                const chatThread = updatedState[matchingItemIndex];
                chatThread.chatHistoryLimit = chatHistoryLimit;
            }
            return updatedState;
        },
        setThreadSkillsAction: (
            state,
            action: PayloadAction<{ threadId: number; skills: Skill[] }>,
        ) => {
            const { threadId, skills } = action.payload;
            const updatedState = cloneState(state);
            const matchingItemIndex = updatedState.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                const chatThread = updatedState[matchingItemIndex];
                chatThread.skills = skills;
            }
            return updatedState;
        },
        setThreadSkillAction: (
            state,
            action: PayloadAction<{
                threadId: Number;
                skillId: number;
                isSelected: boolean;
            }>,
        ) => {
            const { threadId, skillId, isSelected } = action.payload;
            const updatedState = cloneState(state);
            const matchingItemIndex = updatedState.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                const chatThread = updatedState[matchingItemIndex];
                const skill = chatThread.skills?.find(
                    (s: Skill) => s.id === skillId,
                );
                if (skill) {
                    skill.isSelected = isSelected;
                }
            }
            return updatedState;
        },
        setMessageReactionAction: (
            state,
            action: PayloadAction<{
                threadId: number;
                messageId: number;
                reaction: MessageReactionType;
            }>,
        ) => {
            const { threadId, messageId, reaction } = action.payload;
            const matchingItemIndex = state.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                const chatThread = state[matchingItemIndex];
                const message = chatThread.chatResponses?.find(
                    (m: ChatResponseApiResource) => m.id === messageId,
                );
                if (message) {
                    message.thumbDown = reaction === 'ThumbDown';
                    message.thumbUp = reaction === 'ThumbUp';
                }
            }
        },
        setSelectedFileAction: (
            state,
            action: PayloadAction<{
                threadId: number;
                selectedFile: SelectedFile;
            }>,
        ) => {
            const { threadId, selectedFile } = action.payload;
            const matchingItemIndex = state.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                const chatThread = state[matchingItemIndex];
                try {
                    if (!chatThread.files) {
                        chatThread.files = [selectedFile];
                    } else {
                        chatThread.files.push(selectedFile);
                    }
                } catch (e) {
                    console.error('error', e);
                }
            }
            return state;
        },
        deleteSelectedFileAction: (
            state,
            action: PayloadAction<{
                threadId: number;
                selectedFile: SelectedFile;
            }>,
        ) => {
            const { threadId, selectedFile } = action.payload;
            const matchingItemIndex = state.findIndex(
                (c: ChatThreadModel) => c.id == threadId,
            );
            if (matchingItemIndex >= 0) {
                const chatThread = state[matchingItemIndex];
                const fileIndex = chatThread.files!.findIndex(
                    (file: SelectedFile) => {
                        if (file.attachmentId) {
                            return (
                                selectedFile.attachmentId === file.attachmentId
                            );
                        }
                        if (file.url) {
                            return selectedFile.url === file.url;
                        }
                    },
                );
                if (fileIndex >= 0) {
                    chatThread.files!.splice(fileIndex, 1);
                }
            }
            return state;
        },
    },
});

const cloneState = (state: any) => {
    return state.map(({ ...item }) => {
        return item;
    });
};

const unselectAllThreads = (state: any) => {
    return state.map(({ ...item }) => {
        item.isSelected = false;
        return item;
    });
};
