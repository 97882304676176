import { z } from 'zod';
//User zod schema
export const BannerModelSchema = z.object({
    id: z.number(),
    message: z.string(),
    isHidden: z.boolean(),
});

//Export banner model
export type Banner = z.infer<typeof BannerModelSchema>;
