import { Spinner, tokens } from '@fluentui/react-components';
import { DismissFilled } from '@fluentui/react-icons';
import { useState } from 'react';

import { AttachedFile } from 'Models/ChatThread';
import {
    useDownloadThreadAttachmentQuery,
    useRemoveThreadAttachmentMutation,
} from 'Services/API/Aurora';
import { openImageInTab, openNewTab } from 'Utils';

type Props = {
    file: AttachedFile;
    threadId?: number;
    index: number;
    isMessage?: boolean;
};

export const ImageThumbnail = ({
    file,
    threadId,
    index,
    isMessage,
}: Props): JSX.Element => {
    const [isHovered, setIsHovered] = useState(false);
    const [removeThreadAttachment, { isLoading: isRemovingAttachment }] =
        useRemoveThreadAttachmentMutation();

    const { data: blobUrl, isLoading: isLoadingImage } =
        useDownloadThreadAttachmentQuery(
            {
                threadId: threadId ?? 0,
                attachmentId: file.id,
                fileName: file.name,
            },
            {
                skip:
                    !threadId ||
                    (Boolean(file.url) && file.url?.startsWith('http')),
            },
        );

    return (
        <div
            style={{
                marginBottom: '5px',
                backgroundColor: 'var(--colorNeutralBackground5Hover)',
                backgroundImage: `url(${blobUrl ?? file.url}`,
                border: '1px solid var(--colorNeutralBackground3Hover)',
                height: isMessage ? '200px' : '70px',
                width: isMessage ? '200px' : '70px',
                maxWidth: '200px',
                maxHeight: '200px',
                position: 'relative',
                backgroundSize: 'cover',
                borderRadius: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            }}
            onClick={() => {
                if (blobUrl) {
                    const newTab = openNewTab();
                    openImageInTab(blobUrl ?? '', newTab);
                    return;
                }
                window.open(file.url, '_blank');
            }}
            rel="noopener noreferrer"
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {(isLoadingImage || isRemovingAttachment) && (
                <Spinner size="small" />
            )}
            {!isMessage && (blobUrl || file.url) && (
                <div
                    style={{
                        backgroundColor: isHovered
                            ? tokens.colorNeutralForeground3Hover
                            : tokens.colorNeutralBackground1,
                        borderRadius: '9999px',
                        border: tokens.colorNeutralBackgroundInvertedDisabled,
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        fontSize: '16px',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <DismissFilled
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeThreadAttachment({
                                threadId: threadId ?? 0,
                                attachmentId: file.id ?? 0,
                            });
                        }}
                        color={
                            isHovered
                                ? tokens.colorNeutralBackground1
                                : tokens.colorNeutralForeground2
                        }
                    />
                </div>
            )}
        </div>
    );
};
