import { StarterPrompt } from 'Models/StarterPrompt';

import { AuroraEndpointBuilder } from '..';

export const getPrompts = (builder: AuroraEndpointBuilder) =>
    builder.query<StarterPrompt[], boolean>({
        query: (isDocumentInferencing) =>
            isDocumentInferencing
                ? '/v1/Prompts/GetPrompts?DocumentInferencing=true'
                : '/v1/Prompts/GetPrompts',
        transformResponse: (response: { data: StarterPrompt[] }) => {
            return response.data;
        },
        transformErrorResponse: (response: { status: string | number }) => {
            return response.status;
        },
    });
