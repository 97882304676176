import { useMsal } from '@azure/msal-react';
import { v4 as uuidv4 } from 'uuid';

import { RequestHeaders } from '../../Constants';
import { acquireToken } from '../../Helpers/MsalHelper';
import * as WebRequestHelper from '../../Helpers/WebRequestHelper';
import useAppSelector from '../../Hooks/useAppSelector';
import useLogging from '../../Hooks/useLogging';
import { OperationContext } from '../../Models/OperationContext';

type AuroraApiRequestParameters = {
    path: string; // API path e.g. /v1/Threads/GetThreads
    method?: WebRequestHelper.RequestMethods;
    data?: any;
    responseType?: string;
};

type AuroraApiResponse = {
    data?: any;
    contentType?: string;
    statusCode?: number;
    headers: Record<string, string>;
};

const useAuroraApi = () => {
    const { instance, accounts } = useMsal();
    // can we put this in the global store????
    const { logOperation, logError } = useLogging();
    const sessionId = useAppSelector((store) => store.globalState).sessionId;
    const endpoint = process.env.REACT_APP_API_ENDPOINT!;

    const processApiRequest = async (
        parameters: AuroraApiRequestParameters,
    ): Promise<AuroraApiResponse> => {
        const { path, method, data, responseType } = parameters;
        const context: OperationContext = {
            id: uuidv4(),
            name: path,
            startTime: new Date(),
        };
        try {
            const accessToken = await getAccessToken();
            const response = await WebRequestHelper.processWebRequest({
                url: `${endpoint}${path.startsWith('/') ? path : '/' + path}`,
                method: method ?? WebRequestHelper.RequestMethods.GET,
                headers: {
                    [RequestHeaders.Authorization]: `Bearer ${accessToken}`,
                    [RequestHeaders.ContentType]: 'application/json',
                    [RequestHeaders.RequestId]: context.id,
                    [RequestHeaders.SessionId]: sessionId,
                },
                data: parameters.data,
                responseType: responseType ?? 'json',
            });
            return {
                data: response.data,
                contentType: response.contentType,
                statusCode: response.statusCode,
                headers: response.headers,
            };
        } catch (error: any) {
            logError(error, context);
            throw error;
        } finally {
            logOperation(context);
        }
    };

    const getAccessToken = async (): Promise<string | undefined> => {
        const account = accounts[0];
        return await acquireToken(instance, account, [
            process.env.REACT_APP_API_REQUEST_SCOPE!,
        ]);
    };

    return { processApiRequest };
};

export default useAuroraApi;
