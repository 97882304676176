import {
    makeStyles,
    Body1,
    Button,
    shorthands,
    Card,
    Skeleton,
    SkeletonItem,
    CardHeader,
    CardPreview,
    Divider,
    Text,
} from '@fluentui/react-components';
import {
    DocumentCopyRegular,
    CheckboxCheckedRegular,
} from '@fluentui/react-icons';
import * as React from 'react';
import { useState, useRef } from 'react';

import {
    ChatResponseContentApiResource,
    ChatResponseApiResource,
} from '../../Models/ChatThread';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        backgroundColor: 'var(--colorNeutralBackground1)',
    },
    CardPreview: {},
    header2: {
        width: '300px',
        marginLeft: '50px',
        backgroundColor: 'var(--colorNeutralBackground2)',
        paddingLeft: '7px',
        paddingRight: '7px',
        paddingTop: '7px',
        paddingBottom: '7px',
    },
    refHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    copyButton: {
        position: 'absolute', // Position absolute for the button
        top: '10px', // Distance from the top of the container
        right: '10px', // Distance from the right of the container
        zIndex: 1, // Ensure the button is above the code block
    },
});

type LoadChatCardsProps = {};

const SkeletonChatCards: React.FC<LoadChatCardsProps> = (
    props: LoadChatCardsProps,
) => {
    const styles = useStyles();
    return (
        <div
            className="ERM-custom-scrollbar"
            style={{
                height: '100%',
                width: '100%',

                overflowY: 'scroll',
                bottom: 0,
            }}
            ref={(div) => {
                if (div) {
                    // Setting scrollTop to the maximum value will scroll to the bottom
                    div.scrollTop = div.scrollHeight;
                }
            }}
        >
            <div
                style={{
                    height: '100%',
                }}
            />
            <Card
                className={styles.card}
                style={{
                    opacity: 0.8,
                    backgroundColor: 'var(--colorNeutralBackground2)',
                }}
            >
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            width: '70px',
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
            </Card>
            <Card
                className={styles.card}
                style={{
                    opacity: 0.5,
                }}
            >
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            width: '70px',
                            backgroundColor: 'var(--colorNeutralBackground2)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground2)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground2)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
            </Card>
            <Card
                className={styles.card}
                style={{
                    opacity: 0.8,
                    backgroundColor: 'var(--colorNeutralBackground2)',
                }}
            >
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            width: '70px',
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
            </Card>
            <Card
                className={styles.card}
                style={{
                    opacity: 0.5,
                }}
            >
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            width: '70px',
                            backgroundColor: 'var(--colorNeutralBackground2)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground2)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground2)',
                            marginTop: '10px',
                        }}
                    />
                </Skeleton>
            </Card>
        </div>
    );
};

export default SkeletonChatCards;
