import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserProfile } from '../../../Models/UserProfile';

const initialState: Partial<UserProfile> = {};

export const UserProfileSlice = createSlice({
    name: 'userProfile',
    initialState: initialState,
    reducers: {
        updateUserProfileAction: (
            state,
            action: PayloadAction<UserProfile>,
        ) => {
            return { ...state, ...action.payload };
        },
        updateUserProfilePictureAction: (
            state,
            action: PayloadAction<UserProfile['profilePictureUrl']>,
        ) => {
            return { ...state, profilePictureUrl: action.payload };
        },
    },
});
