//useChatThread.ts

import * as WebRequestHelper from '../../Helpers/WebRequestHelper';
import { User, UserModelSchema } from '../../Models/User';
import {
    setErrorMessage,
    updateUserAction,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import { validateDataAgainstSchema } from '../../Utils';
import useAuroraApi from '../useAuroraApi';

//Helper for validating schema
const checkForInvalidData = (data: User | null): void => {
    if (!data) {
        throw new Error('Data is null');
    }
    // Validate the data against the schema
    const isValidSchema = validateDataAgainstSchema<User>(
        data,
        UserModelSchema,
    );
    // If the data does not match the schema, throw an error
    if (!isValidSchema) {
        throw new Error('Data does not match schema');
    }
};

const useUser = () => {
    //Helper for API calls
    const { processApiRequest } = useAuroraApi();

    //Get User
    const getUser = async (): Promise<User | undefined> => {
        // make API call to fetch the threads.
        try {
            const response = await processApiRequest({
                //todo: update the path to the correct endpoint.
                path: '/v1/Users/GetUser',
            });
            // handle the response here
            const data = response.data.data as User;
            try {
                checkForInvalidData(data);
            } catch (error) {
                updateStore(
                    setErrorMessage({
                        message: 'Error fetching user profile',
                        debugInfo: JSON.stringify(error),
                    }),
                );
                return;
            }
            // update User in store.
            updateStore(updateUserAction(data));
            return data;
        } catch (error) {
            console.error(
                'Error occurred while processing API request:',
                error,
            );
            // throw error;
            // handle the error here
        }
    };

    //Update User
    const updateUser = async (user: User): Promise<void> => {
        try {
            // Make API call to update the user with the specified user
            const response = await processApiRequest({
                path: '/v1/Users/UpdateUser',
                method: WebRequestHelper.RequestMethods.PATCH,
                data: user,
            });
            const data = response.data.data as User;
            try {
                checkForInvalidData(data);
            } catch (error) {
                updateStore(
                    setErrorMessage({
                        message: 'Error updating user profile',
                        debugInfo: JSON.stringify(error),
                    }),
                );
            }
        } catch (error) {
            console.info(
                'Error occurred while processing API request: Users/UpdateUser',
                error,
            );
            throw error;
        }
    };
    return { getUser, updateUser };
};

export default useUser;
