import { useMsal } from '@azure/msal-react';
import {
    Textarea,
    makeStyles,
    CardHeader,
    Card,
    Body1,
    InfoLabel,
    Skeleton,
    SkeletonItem,
    Spinner,
} from '@fluentui/react-components';
import { QueryStatus } from '@reduxjs/toolkit/query';
import React, { useState, useEffect } from 'react';

import { ERROR_MESSAGE } from 'Constants';
import {
    useGetThreadDetailsQuery,
    usePostAttachOneDriveFileToThreadMutation,
} from 'Services/API/Aurora';

import * as GraphApiHelper from '../../Helpers/GraphApiHelper';
import * as MsalHelper from '../../Helpers/MsalHelper';
import {
    closeDocumentSearch,
    setToastMessage,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import { getFileIcon, validateFile } from '../../Utils/index';

import '../../Styles/ChatCards.Module.css';

type DocumentPopoverProps = {
    threadId?: number;
    setLoadingSelectedDocument: (loading: boolean) => void;
};

interface Document {
    fileId: string;
    name: string;
    url: string;
    date: string;
    size: number; // include the file size
    driveId: string;
    siteId: string;
    listId: string;
    listItemId: string;
}

const useStyles = makeStyles({
    card: {
        width: '98%',
        marginTop: '2px',
        backgroundColor: 'var(--colorNeutralBackground2)',
        height: '70px',
        ':hover': {
            backgroundColor: 'var(--colorNeutralBackground1)',
        },
    },

    header2: {
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    disabledCard: {
        width: '98%',
        marginTop: '2px',
        backgroundColor: 'var(--colorNeutralBackgroundDisabled)',
        color: 'var(--colorNeutralForegroundDisabled)',
        pointerEvents: 'none', // Prevents interactions
        opacity: 0.5,
    },
});

const DocumentPopover: React.FC<DocumentPopoverProps> = (
    props: DocumentPopoverProps,
) => {
    const { threadId, setLoadingSelectedDocument } = props;
    const styles = useStyles();
    const { instance, accounts } = useMsal();
    const [searchTerm, setSearchTerm] = useState('');
    const account = accounts[0];
    const [recentDocuments, setRecentDocuments] = useState<Document[]>([]);
    const [textareaValue, setTextareaValue] = useState('');
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [uploadingFileIndex, setUploadingFileIndex] = useState<number | null>(
        null,
    );

    const { data: threadDetails } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const files = threadDetails?.attachments;

    const [attachOneDriveFile, { status, isLoading: isUploadingFile }] =
        usePostAttachOneDriveFileToThreadMutation();

    useEffect(() => {
        if (status === QueryStatus.rejected) {
            updateStore(
                setToastMessage({
                    title: ERROR_MESSAGE.FileUpload,
                    body: 'Please try again.',
                    position: 'bottom',
                }),
            );
        }
        if (
            status === QueryStatus.fulfilled ||
            status === QueryStatus.rejected
        ) {
            updateStore(closeDocumentSearch());
            setUploadingFileIndex(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    // Disable card if it is already selected, or if it exceeds specified document size, or if it is a file crossover
    function shouldDisableCard(doc: Document): boolean {
        const sizeExceedsLimit = doc.size > 100000000;

        let fileTypeCrossover = false;
        //If a file is already selected
        if (files && files.length > 0) {
            //If selected file is an image
            if (
                files[0].name.endsWith('.png') ||
                files[0].name.endsWith('.jpg') ||
                files[0].name.endsWith('.jpeg')
            ) {
                fileTypeCrossover = !(
                    doc.name.endsWith('.png') ||
                    doc.name.endsWith('.jpg') ||
                    doc.name.endsWith('.jpeg')
                );
            }
            //If selected file is a document
            else {
                fileTypeCrossover =
                    doc.name.endsWith('.png') ||
                    doc.name.endsWith('.jpg') ||
                    doc.name.endsWith('.jpeg');
            }
        }

        return sizeExceedsLimit || fileTypeCrossover;
    }

    // Returns the reason why the card is disabled
    function getDisabledReason(doc: Document): string {
        if (doc.size > 100000000) {
            return 'File size too large';
        } else if (files && files.length > 0) {
            if (
                files[0].name.endsWith('.png') ||
                files[0].name.endsWith('.jpg') ||
                files[0].name.endsWith('.jpeg')
            ) {
                return 'Please removed the selected image before selecting a document';
            }
            return 'Please removed the selected document before selecting an image';
        }
        return '';
    }

    //Return the file name without an extension for easier reading if it has an icon
    function getFileNameWithoutExtension(fileName: string) {
        if (
            fileName.endsWith('.ppt') ||
            fileName.endsWith('.pptx') ||
            fileName.endsWith('.doc') ||
            fileName.endsWith('.docx') ||
            fileName.endsWith('.xls') ||
            fileName.endsWith('.xlsx') ||
            fileName.endsWith('.csv') ||
            fileName.endsWith('.pdf') ||
            fileName.endsWith('.asp') ||
            fileName.endsWith('.aspx') ||
            fileName.endsWith('.html')
        ) {
            return fileName.replace(/\.[^/.]+$/, '');
        }
        return fileName;
    }

    // Displays date opened
    function formatLastOpenedDate(lastAccessed: string): string {
        const lastAccessedDate = new Date(lastAccessed);
        const now = new Date();
        const timeDiff = now.getTime() - lastAccessedDate.getTime();
        const minutesDiff = timeDiff / (1000 * 60);

        if (minutesDiff < 60) {
            // Less than an hour, show minutes
            const roundedMinutes = Math.round(minutesDiff);
            return `Last modified ${roundedMinutes} minute${roundedMinutes !== 1 ? 's' : ''} ago`;
        } else if (minutesDiff < 24 * 60) {
            // Less than a day, show hours
            const hoursDiff = Math.round(minutesDiff / 60);
            return `Last modified ${hoursDiff} hour${hoursDiff !== 1 ? 's' : ''} ago`;
        }
        // More than a day, show days
        const daysDiff = Math.round(minutesDiff / (60 * 24));
        return `Last modified ${daysDiff} day${daysDiff !== 1 ? 's' : ''} ago`;
    }

    // fucntion to get recent files
    useEffect(() => {
        async function fetchRecentFiles() {
            setLoadingDocuments(true);
            const accessToken = await MsalHelper.acquireToken(
                instance,
                account,
                ['User.Read'],
                false,
            );
            try {
                const recentFiles = await GraphApiHelper.getRecentFiles(
                    accessToken!,
                    searchTerm,
                );

                setLoadingDocuments(false);
                setRecentDocuments(recentFiles);
            } catch (error) {
                console.error('Error fetching recent files:', error);
            }
        }
        fetchRecentFiles();
    }, [instance, account, searchTerm]);

    // Function that handles typing in the text area
    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value;
        setSearchTerm(newValue);
        setTextareaValue(newValue);
    };

    const handleCardClick = (doc: Document, index: number) => {
        if (!threadId) {
            return;
        }

        if ((threadDetails?.attachments?.length ?? 0) >= 2) {
            updateStore(
                setToastMessage({
                    title: 'You cannot attach more than 2 files',
                    position: 'bottom',
                }),
            );
            return;
        }

        setUploadingFileIndex(index);
        setLoadingSelectedDocument(true);

        try {
            validateFile(doc.name, files, doc.url);
        } catch (e) {
            if (e instanceof Error) {
                updateStore(
                    setToastMessage({
                        title: e.message,
                        position: 'bottom',
                    }),
                );
                return;
            }
        }

        // Update the inserted file name
        attachOneDriveFile({
            fileId: doc.fileId,
            driveId: doc.driveId,
            threadId,
            fileName: doc.name,
        });

        setLoadingSelectedDocument(false);
    };

    return (
        <div style={{ height: '100%' }}>
            <b style={{ marginLeft: '20px' }}>My Office 365 Files</b>
            <div>
                <Textarea
                    onChange={handleOnChange}
                    value={textareaValue}
                    style={{
                        backgroundColor: 'var(--colorNeutralBackground2)',
                        height: '32px',
                        width: '97%',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}
                ></Textarea>
            </div>
            <div
                className="custom-scrollbar"
                style={{
                    overflow: 'auto',
                    height: '84%',
                }}
            >
                {(() => {
                    const filteredDocuments = recentDocuments;

                    if (loadingDocuments) {
                        return (
                            <div>
                                {[...Array(4)].map((_, i) => (
                                    <div key={i}>
                                        <Skeleton animation="pulse">
                                            <SkeletonItem
                                                className={styles.card}
                                                style={{
                                                    height: '70px',

                                                    backgroundColor:
                                                        'var(--colorNeutralBackground2)',
                                                }}
                                            />
                                        </Skeleton>
                                    </div>
                                ))}
                            </div>
                        );
                    } else if (filteredDocuments.length === 0) {
                        return (
                            <div
                                style={{
                                    marginTop: '20px',
                                    marginLeft: '20px',
                                    fontSize: '12px',
                                }}
                            >
                                <Body1> No files found</Body1>
                            </div>
                        );
                    }
                    return (
                        <div
                            style={{
                                height: '100%',
                            }}
                        >
                            {filteredDocuments.map((doc, index) => (
                                <Card
                                    key={index}
                                    tabIndex={0}
                                    className={` ${shouldDisableCard(doc) ? styles.disabledCard : styles.card}`}
                                    onClick={() => handleCardClick(doc, index)}
                                >
                                    <CardHeader
                                        image={
                                            isUploadingFile &&
                                            index === uploadingFileIndex ? (
                                                <Spinner size="tiny" />
                                            ) : (
                                                <img
                                                    src={getFileIcon(
                                                        doc.name,
                                                        doc.url,
                                                    )}
                                                    alt="File icon"
                                                    style={{
                                                        width: '20px',
                                                    }}
                                                />
                                            )
                                        }
                                        header={
                                            <b>
                                                {getFileNameWithoutExtension(
                                                    doc.name,
                                                )}
                                            </b>
                                        }
                                        description={
                                            <Body1 style={{ fontSize: '12px' }}>
                                                {formatLastOpenedDate(doc.date)}{' '}
                                                {/* Size: {doc.size} bytes */}
                                                {shouldDisableCard(doc) && (
                                                    <>
                                                        <InfoLabel
                                                            info={<></>}
                                                            style={{
                                                                fontSize:
                                                                    '12px',
                                                                marginLeft:
                                                                    '5px',
                                                            }}
                                                        >
                                                            {' '}
                                                        </InfoLabel>

                                                        <Body1
                                                            style={{
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            {getDisabledReason(
                                                                doc,
                                                            )}
                                                            {/* {getDisabledReason(doc)} */}
                                                        </Body1>
                                                    </>
                                                )}
                                            </Body1>
                                        }
                                    />{' '}
                                </Card>
                            ))}
                        </div>
                    );
                })()}
            </div>
        </div>
    );
};

export default DocumentPopover;
