import { MutableRefObject, Ref, useEffect, useState } from 'react';

export const useIntersection = (
    element: MutableRefObject<HTMLDivElement | null>,
    rootMargin: string,
) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        if (!element?.current) {
            return;
        }
        const { current } = element;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            { rootMargin },
        );
        current && observer?.observe(current);

        return () => current && observer.unobserve(current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isVisible;
};
