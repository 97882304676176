import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import React, { ReactElement, ReactNode } from 'react';

type Props = {
    trigger: ReactElement;
    labelA: ReactNode;
    labelB: ReactNode;
    handleSelectA: React.MouseEventHandler<HTMLDivElement>;
    handleSelectB: React.MouseEventHandler<HTMLDivElement>;
};

export const PopoverMenu = ({
    trigger,
    labelA,
    labelB,
    handleSelectA,
    handleSelectB,
}: Props) => {
    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>{trigger}</MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem onClick={handleSelectA}>{labelA}</MenuItem>
                    <MenuItem onClick={handleSelectB}>{labelB}</MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
