import {
    BaseQueryFn,
    createApi,
    EndpointBuilder,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { v4 as uuidv4 } from 'uuid';

import { RequestHeaders } from '../../../Constants';
import { acquireToken } from '../../../Helpers/MsalHelper';
import { msalInstance } from '../../../index';
import { OperationContext } from '../../../Models/OperationContext';
import { logError, logOperation } from '../../../Utils/logging';
import { setErrorMessage } from '../../StateManagement/Actions';
import { RootState } from '../../StateManagement/store';

import { deleteThread } from './endpoints/deleteThread';
import { downloadThreadAttachment } from './endpoints/downloadThreadAttachment';
import { getPrompts } from './endpoints/getPrompts';
import { getThreadDetails } from './endpoints/getThreadDetails';
import { getThreadMessages } from './endpoints/getThreadMessages';
import { getThreads } from './endpoints/getThreads';
import { postMessageReaction } from './endpoints/patchMessageReaction';
import { patchThread } from './endpoints/patchThread';
import { patchThreadChatHistoryLimit } from './endpoints/patchThreadChatHistoryLimit';
import { patchThreadSkills } from './endpoints/patchThreadSkills';
import { postAttachLocalFileToThread } from './endpoints/postAttachLocalFileToThread';
import { postAttachOneDriveFileToThread } from './endpoints/postAttachOneDriveFileToThread';
import { postMessage } from './endpoints/postMessage';
import { postThread } from './endpoints/postThread';
import { removeThreadAttachment } from './endpoints/removeThreadAttachment';

const baseUrl = process.env.REACT_APP_API_ENDPOINT!;

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, api) => {
        const { extra } = api as { extra: { context: OperationContext } };
        const accessToken = await acquireToken(
            msalInstance,
            msalInstance.getActiveAccount() ?? undefined,
            [process.env.REACT_APP_API_REQUEST_SCOPE!],
        );
        headers.set(RequestHeaders.Authorization, `Bearer ${accessToken}`);
        headers.set(RequestHeaders.ContentType, 'application/json');
        headers.set(RequestHeaders.RequestId, extra.context.id);
        headers.set(RequestHeaders.SessionId, extra.context.id);
        return headers;
    },
});

const baseQueryWithLogging: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const context: OperationContext = {
        id: uuidv4(),
        name: api.endpoint,
        startTime: new Date(),
    };
    let result = await baseQuery(
        args,
        { ...api, extra: { context } },
        extraOptions,
    );

    if (result.error) {
        const { globalState } = api.getState() as RootState;
        logError(result.error as any, context, globalState.sessionId);
        logOperation(context, globalState.sessionId);
        api.dispatch(
            setErrorMessage({
                message: 'Error processing Aurora API call',
                debugInfo: '',
                requestId: context.id,
            }),
        );
        // TODO: any global error messages for all api calls can be handled here
    }
    return result;
};

export type AuroraEndpointBuilder = EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Tag,
    ReducerPath
>;

// Cache tags
export const tags = ['Messages', 'ThreadDetails', 'Threads'];
export type Tag = (typeof tags)[number];

export const reducerPath = 'auroraApi';
export type ReducerPath = typeof reducerPath;

// Define a service using a base URL and expected endpoints
export const auroraApi = createApi({
    reducerPath,
    baseQuery: baseQueryWithLogging,
    tagTypes: tags,
    endpoints: (builder: AuroraEndpointBuilder) => ({
        getThreads: getThreads(builder),
        getThreadDetails: getThreadDetails(builder),
        getThreadMessages: getThreadMessages(builder),
        getPrompts: getPrompts(builder),
        postThread: postThread(builder),
        patchThread: patchThread(builder),
        patchThreadChatHistoryLimit: patchThreadChatHistoryLimit(builder),
        postMessageReaction: postMessageReaction(builder),
        deleteThread: deleteThread(builder),
        patchThreadSkills: patchThreadSkills(builder),
        postMessage: postMessage(builder),
        postAttachLocalFileToThread: postAttachLocalFileToThread(builder),
        postAttachOneDriveFileToThread: postAttachOneDriveFileToThread(builder),
        removeThreadAttachment: removeThreadAttachment(builder),
        downloadThreadAttachment: downloadThreadAttachment(builder),
    }),
});

export const {
    useGetThreadsQuery,
    useGetThreadDetailsQuery,
    useGetThreadMessagesQuery,
    useGetPromptsQuery,
    usePatchThreadSkillsMutation,
    usePostThreadMutation,
    usePostMessageMutation,
    useDeleteThreadMutation,
    usePatchThreadMutation,
    usePostMessageReactionMutation,
    usePatchThreadChatHistoryLimitMutation,
    usePostAttachLocalFileToThreadMutation,
    usePostAttachOneDriveFileToThreadMutation,
    useRemoveThreadAttachmentMutation,
    useDownloadThreadAttachmentQuery,
    useLazyDownloadThreadAttachmentQuery,
} = auroraApi;
