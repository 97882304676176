import { ContextTagKeys } from '@microsoft/applicationinsights-common';
import {
    ITraceTelemetry,
    SeverityLevel,
    IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { Middleware, MiddlewareAPI } from 'redux';

import { OperationContext } from '../Models/OperationContext';
import { appInsights } from '../Services/AppInsights';

export const logOperation = (context: OperationContext, sessionId: string) => {
    // context.userId = accounts[0]?.localAccountId;
    // context.userName = accounts[0]?.username;
    const traceTelemetry: ITraceTelemetry = {
        message: context.name,
        severityLevel: SeverityLevel.Verbose,
        properties: {
            name: context.name,
            tags: getTagsFromContext(context, sessionId),
        },
    };
    appInsights.trackTrace(
        traceTelemetry,
        getCustomPropertiesFromContext(context, sessionId),
    );
};

export const logError = (
    error: Error,
    context: OperationContext,
    sessionId: string,
) => {
    const exceptionTelemetry: IExceptionTelemetry = {
        error: error,
        severityLevel: SeverityLevel.Error,
        properties: {
            name: context.name,
            tags: getTagsFromContext(context, sessionId),
        },
    };
    appInsights.trackException(
        exceptionTelemetry,
        getCustomPropertiesFromContext(context, sessionId),
    );
};

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (isRejectedWithValue(action)) {
            // toast.warn({
            //     title: 'Async error!',
            //     message:
            //         'data' in action.error
            //             ? (action.error.data as { message: string }).message
            //             : action.error.message,
            // });
        }

        return next(action);
    };

const getTagsFromContext = (context: OperationContext, sessionId: string) => {
    const contextTagKeys = new ContextTagKeys();
    return {
        [contextTagKeys.sessionId]: sessionId,
        [contextTagKeys.operationId]: context.id,
        [contextTagKeys.userId]: context.userId,
    };
};

const getCustomPropertiesFromContext = (
    context: OperationContext,
    sessionId: string,
) => {
    context.endTime = new Date();
    context.elapsed =
        (context.endTime.getTime() - context.startTime.getTime()) / 1000;
    const customProperties = context.additionalProperties ?? {};
    customProperties['StartTime'] = context.startTime;
    customProperties['EndTime'] = context.endTime;
    customProperties['ElapsedTime'] = context.elapsed;
    customProperties['OperationId'] = context.id;
    customProperties['OperationName'] = context.name;
    customProperties['UserId'] = context.userId;
    customProperties['UserName'] = context.userName;
    customProperties['SessionId'] = sessionId;
    return customProperties;
};
