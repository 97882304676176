import { makeStyles, tokens } from '@fluentui/react-components';
import { DocumentAddRegular } from '@fluentui/react-icons';
import * as React from 'react';

const useStyles = makeStyles({
    content: {
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: tokens.colorBackgroundOverlay,
        opacity: 0.99,
        backdropFilter: 'blur(10px)',
        zIndex: 999,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: tokens.colorStrokeFocus2,
    },
    icon128: { fontSize: '128px' },
    header: { fontSize: '24px' },
    text: { fontSize: '18px' },
});

const Content = () => {
    const styles = useStyles();
    return (
        <div className={styles.content}>
            <div className={styles.container}>
                <DocumentAddRegular className={styles.icon128} />
                <h3 className={styles.header}>Add anything</h3>
                <div className={styles.text}>
                    Drop files here to add them to the conversation
                </div>
            </div>
        </div>
    );
};

type Props = {
    content?: React.ReactNode;
    isOpen: boolean;
};

export const ScreenPopover = ({ content, isOpen }: Props) =>
    isOpen ? <Content /> : <></>;
