import {
    Slider,
    makeStyles,
    Divider,
    Dropdown,
    Option,
    OptionGroup,
    Button,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Skeleton,
    SkeletonItem,
} from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';
import * as React from 'react';

interface SettingsMenuAppearanceProps {}

const useStyles = makeStyles({
    dropdown: {
        maxWidth: '100%',
        minWidth: '100%',
        marginRight: '25px',
    },
    saveButton: {
        maxWidth: '80px',
        marginLeft: 'auto',
        marginRight: '15px',
    },
});

const LoadingSettingsMenuAppearance: React.FC<
    SettingsMenuAppearanceProps
> = ({}) => {
    const styles = useStyles();
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    return (
        <div className="SettingsMenuDefaults">
            <div>
                <label>Conversation Context</label>
                <Popover open={isPopoverOpen}>
                    <PopoverTrigger>
                        <Button
                            appearance="transparent"
                            icon={<InfoRegular />}
                            style={{
                                marginRight: '10px',
                                marginBottom: '2px',
                            }}
                            onMouseEnter={() => setIsPopoverOpen(true)}
                            onMouseLeave={() => setIsPopoverOpen(false)}
                        />
                    </PopoverTrigger>
                    <PopoverSurface
                        tabIndex={-1}
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            fontSize: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            width: '200px',
                        }}
                    >
                        {
                            'Select the number of past messages to include in each new request. This helps give the model context for new user queries. Setting this number to 10 will include 10 user messages and 10 system responses.'
                        }
                    </PopoverSurface>
                </Popover>
            </div>
            <div className="SettingsMenuChatHistoryFlex">
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            width: '150px',
                            backgroundColor: 'var(--colorNeutralBackground2)',
                        }}
                    />
                </Skeleton>
            </div>
            <Divider />
            <label>Default Skills</label>
            <div className="SettingsMenuChatHistoryFlex">
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            width: '200px',
                            height: '30px',
                            backgroundColor: 'var(--colorNeutralBackground2)',
                        }}
                    />
                </Skeleton>
            </div>
            <Divider />
            <div className="SettingsMenuSkillCardsFlex">
                <Skeleton animation="pulse">
                    <SkeletonItem
                        style={{
                            width: '200px',
                            height: '150px',
                            backgroundColor: 'var(--colorNeutralBackground2)',
                        }}
                    />
                </Skeleton>
            </div>
        </div>
    );
};

export default LoadingSettingsMenuAppearance;
