import { AuroraEndpointBuilder } from '..';

export const downloadThreadAttachment = (builder: AuroraEndpointBuilder) =>
    builder.query<
        string,
        { threadId: number; attachmentId: number; fileName: string }
    >({
        queryFn: async (
            { threadId, attachmentId, fileName },
            _api,
            _extraOptions,
            baseQuery,
        ) => {
            const result = await baseQuery({
                url: `/v1/Threads/DownloadThreadAttachment?threadId=${threadId}&attachmentId=${attachmentId}`,
                responseHandler: (response) => response.blob(),
            });

            const url = window.URL || window.webkitURL;
            const objectUrl = url.createObjectURL(result.data as Blob);
            return { data: objectUrl };
        },
    });
