import { IPublicClientApplication } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    MsalProvider,
} from '@azure/msal-react';
import { FluentProvider, Theme } from '@fluentui/react-components';
import * as microsoftTeams from '@microsoft/teams-js';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import { loginRequest } from './auth-config';
import { acquireToken } from './Helpers/MsalHelper';
import useAppSelector from './Hooks/useAppSelector';
import useLogging from './Hooks/useLogging';
import ERM from './Pages/ERM'; // Adjust the path as necessary
import Home from './Pages/Home';
import {
    setAccessToken,
    setIsHostedInTeamsAction,
    updateUserProfileAction,
} from './Services/StateManagement/Actions';
import { updateStore } from './Services/StateManagement/Utils';
import {
    DarkTheme,
    LightTheme,
    TeamsDarkTheme,
    TeamsHighContrastTheme,
    TeamsLightTheme,
} from './Styles/Theme';

type PossibleThemes = 'Light' | 'Dark';

const WrappedView = () => {
    const userPreferences = useAppSelector((store) => store.user);
    const teamsTheme = useRef<string | null>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const utm_source = queryParams.get('utm_source');
        if (utm_source && utm_source === 'msteams') {
            updateStore(setIsHostedInTeamsAction());
            const teamsApp = microsoftTeams?.app;
            if (teamsApp) {
                teamsApp
                    .initialize()
                    .then(() => {
                        teamsApp
                            .getContext()
                            .then((context) => {
                                teamsTheme.current = context.app.theme;
                            })
                            .catch((error) => {
                                console.error(
                                    'error in determining Teams theme',
                                    error,
                                );
                            });
                    })
                    .catch((error) => {
                        console.error('error in initializing Teams app', error);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTheme = (): Theme => {
        if (teamsTheme.current) {
            switch (teamsTheme.current) {
                case 'contrast': {
                    return TeamsHighContrastTheme;
                }
                case 'dark': {
                    return TeamsDarkTheme;
                }
                case 'light': {
                    return TeamsLightTheme;
                }
                case 'classic':
                default: {
                    return window.matchMedia &&
                        window.matchMedia('(prefers-color-scheme: light)')
                            .matches
                        ? TeamsLightTheme
                        : TeamsDarkTheme;
                }
            }
        }
        return userPreferences.theme === 'Light' ? LightTheme : DarkTheme;
    };
    const { instance } = useMsal();

    const activeAccount: AccountInfo | null = instance.getActiveAccount();
    useEffect(() => {
        const userProfile = {
            id: activeAccount?.localAccountId || '',
            name: activeAccount?.name || '',
            email: activeAccount?.username || '',
            profilePictureUrl: '',
        };
        updateStore(updateUserProfileAction(userProfile));
    }, [
        activeAccount?.username,
        activeAccount?.localAccountId,
        activeAccount?.name,
    ]);

    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

    React.useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <FluentProvider
            theme={getTheme()}
            style={{ height: windowHeight, width: '100vw' }}
        >
            <BrowserRouter>
                <AuthenticatedTemplate>
                    {activeAccount ? (
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/ERM" element={<ERM />} />
                            {/* Add more authenticated routes as needed */}
                        </Routes>
                    ) : null}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Routes>
                        {/* Define routes accessible without authentication if needed */}
                        {/* Redirect or show sign-in by default */}
                        <Route
                            path="*"
                            element={
                                <UnauthenticatedRedirect instance={instance} />
                            }
                        />
                    </Routes>
                </UnauthenticatedTemplate>
            </BrowserRouter>
        </FluentProvider>
    );
};

const App = ({ instance }: { instance: IPublicClientApplication }) => {
    return (
        <MsalProvider instance={instance}>
            <WrappedView />
        </MsalProvider>
    );
};

const UnauthenticatedRedirect = ({
    instance,
}: {
    instance: IPublicClientApplication;
}) => {
    useEffect(() => {
        const activeAccount: AccountInfo | null = instance.getActiveAccount();
        if (!activeAccount) {
            const queryParams = new URLSearchParams(window.location.search);
            const loginHint = queryParams.get('login_hint');
            instance
                .loginRedirect({
                    ...loginRequest,
                    prompt: 'create',
                    loginHint: loginHint ?? undefined,
                })
                .catch((error: any) => {
                    console.error('error', error);
                });
        }
    }, [instance]);

    return <></>;
};

export default App;
